<template><div>
<v-row>
  <v-col>
    <v-card
      class="mt-0 mb-6 ml-2 mr-2 mt-4 d-flex flex-column"
      rounded="xl"
      elevation="4"
      style="overflow-y: auto"
      min-height="620"
    >
      <v-card-text>
        <v-expansion-panels
          rounded="xl">
          <v-expansion-panel
            static
            color="secondary">
            <v-expansion-panel-title>
              <v-row>
                <v-col class="d-flex justify-end">
                  Filters and Options
                </v-col>
              </v-row>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              class="mb-n4">
              <v-row>
                <v-col></v-col>
                <v-col class="d-flex justify-end">
                  <xlsxexportbutton class="mr-2" :rawJSON="filteredItems" :updateMessage="updateResultMessage"></xlsxexportbutton>
                  <v-btn
                    variant="tonal"
                    color="primary"
                    icon=""
                    @click="addNew()"
                  >
                    <v-icon icon="mdi-plus-thick" />
                    <v-tooltip
                      activator="parent"
                      location="top"
                    >Add New
                    </v-tooltip>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="mb-6 pb-10"></div>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    v-model="selectedCountry"
                    label="Country"
                    density="compact"
                    auto-select-first
                    clearable
                    :items="countryLookup"
                    :item-title="item => `${item.CountryName} (${item.CountryCode})`"
                    :item-value="item => item.CountryID"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="2"><div class="d-flex justify-end">
                  <v-switch
                    v-model="selectedIsActive"
                    variant="solo"
                    label="Is Active?"
                    density="compact"
                    color="primary"
                  >
                  </v-switch>
                </div></v-col>
                <v-col justify="end">
                  <v-text-field
                      v-model="search"
                      prepend-inner-icon="mdi-magnify"
                      label="Search"
                      density="compact"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-data-table
          density="compact"
          :loading="isLoading"
          :headers="lookupHeaders"
          :items="filteredItems"
          :item-value="lookupPrimaryKey"
          items-per-page="-1"
          hide-default-footer="true">
          <template v-slot:loading>
            <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
          </template>
          <template v-slot:item.IsActive="{ item }">
            <v-icon
              v-if="item.IsActive"
              size="small"
              icon="mdi-check"
              color="success"
            ></v-icon>
            <v-icon
              v-if="!item.IsActive"
              size="small"
              icon="mdi-cancel"
              color="error"
            ></v-icon>
          </template>
          <template v-slot:item.CountryID="{ item }">
            {{ getCountryName(item.CountryID) }}
          </template>
          <template v-slot:item.OfficeManagerID="{ item }">
            <v-btn
              size="small"
              color="secondary"
              icon=""
              variant="text"
              density="compact"
              @click="sendMail(getOfficeManagerEmail(item.OfficeManagerID))"
            ><v-icon icon="mdi-email" />
             <v-tooltip
               activator="parent"
               location="top"
             >Send mail to {{ getOfficeManagerName(item.OfficeManagerID) }}
             </v-tooltip></v-btn>
            {{ getOfficeManagerName(item.OfficeManagerID) }}
          </template>
          <template v-slot:item.OfficeAddress="{ item }">
            <v-btn
              size="small"
              :color="item.OfficeAddress ? `primary` : `warning`"
              icon=""
              variant="text"
              density="compact"
              @click="openGoogleMapsUrl(item.OfficeAddress)"
            ><v-icon icon="mdi-map-marker" />
             <v-tooltip
               activator="parent"
               location="top"
             >{{ formatAddress(item.OfficeAddress) }}
             </v-tooltip></v-btn>
          </template>
          <template v-slot:item.actions="{ item }">
            <div>
              <v-btn
                color="primary"
                icon=""
                variant="plain"
                density="compact"
                @click="showEdit(item.OfficeID)"
              ><v-icon size="sm" icon="mdi-eye" />
                <v-tooltip
                  activator="parent"
                  location="top"
                >View {{  item.Name }}
                </v-tooltip>
              </v-btn>
            </div>
          </template>
          <template v-slot:bottom> </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>
<v-row><v-col></v-col></v-row>

<v-dialog v-model="showDialogEditItem" width="750px">
  <v-card>
    <v-card-title>Edit {{ lookupNameReadable }}</v-card-title>
    <v-card-text>
      <v-row><v-col></v-col></v-row>
      <v-row>
        <v-col class="pa-0 ma-1" cols="2"><v-text-field
          density="compact"
          persistent-placeholder
          variant="plain"
          readonly
          type="number"
          label="ID"
          v-model="editItem.OfficeID"
        /></v-col>
        <v-col>
          <div class="d-flex justify-end mt-n6"><v-switch
            density="compact"
            color="primary"
            label="Is Active?"
            v-model="editItem.IsActive"
          ></v-switch></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0 ma-1"><v-text-field
          density="compact"
          persistent-placeholder
          variant="underlined"
          label="Office Name"
          v-model="editItem.OfficeName"
        /></v-col>
        <v-col class="pa-0 ma-1"><v-autocomplete
          density="compact"
          persistent-placeholder
          variant="underlined"
          label="Country Name"
          v-model="editItem.CountryID"
          :items="countryLookup"
          :item-title="item => `${item.CountryName} (${item.CountryCode})`"
          :item-value="item => item.CountryID"
        /></v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0 ma-1">
          <v-textarea
            density="compact"
            persistent-placeholder
            variant="underlined"
            no-resize
            rows="4"
            label="Office Address"
            v-model="editItem.OfficeAddress"
          />
        </v-col>
        <v-col class="pa-0 ma-1 d-flex align-end"><v-autocomplete
            class=""
            density="compact"
            persistent-placeholder
            variant="underlined"
            label="Office Manager"
            v-model="editItem.OfficeManagerID"
            :items="officeManagerLookup"
            :item-title="item => `${item.Name} (${item.CompanyCode})`"
            :item-value="item => item.UserID"
          /></v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        @click="saveEditItem(editItem.OfficeID)"
      >Save</v-btn>
      <v-btn
        color="error"
        @click="cancelEditItem()"
      >Cancel</v-btn>
    </v-card-actions>
  </v-card>

</v-dialog>

</div></template>
<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import authService from '@/services/authService'
import utils from '@/services/utils'
import { useDate } from 'vuetify'
import { useRouter } from 'vue-router'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const props = defineProps({
  updateMessage: Function
})
var search = ref(null)
var filteredItems = ref([])
var selectedIsActive = ref(true)
var selectedCountry = ref(null)

const lookupPrimaryKey = "OfficeID"
const lookupName = "Offices"
const lookupNameReadable = "Offices"

const lookupHeaders = [
  { title: "", key: "IsActive", value: "IsActive", align: "center", width: "50px" },
  { title: "ID", key: "OfficeID", value: "OfficeID", width: "90px"},
  { title: "Name", key: "OfficeName", value: "OfficeName" },
  { title: "Country", key: "CountryID", value: "CountryID", sortable: false },
  { title: "Office Manager", key: "OfficeManagerID", value: "OfficeManagerID" },
  { title: "Address", key: "OfficeAddress", value: "OfficeAddress", align: "center" },
  { title: "Active Users", key: "ActiveUsers", value: "ActiveUsers", width: "140px" },
  { title: "Actions", key: "actions", width: "90px", sortable: false },
]

var editItem = ref({})

var showDialogEditItem = ref(false)

const countryLookup = ref([])
const officeManagerLookup = ref([])

var lookupItems = ref([])
const isLoading = ref(false)

function updateResultMessage(message) {
  props.updateMessage(message)
}

function getCountryName(countryID) {
  const country = Array.isArray(countryLookup.value) ? countryLookup.value.find(c => c.CountryID === countryID) : countryLookup.value
  return country ? `${country.CountryName} (${country.CountryCode})` : countryID
}

async function getCountryLookup() {
  await utils.httpRequest(`/Static/Countries/`, utils.REQUEST.GET, (data) => {
    countryLookup.value = data.filter(item => (item.IsActive == true))
  })
}

function getOfficeManagerName(userID) {
  const user = Array.isArray(officeManagerLookup.value) ? officeManagerLookup.value.find(c => c.UserID === userID) : officeManagerLookup.value
  return user ? `${user.Name}` : userID
}

function getOfficeManagerEmail(userID) {
  const user = Array.isArray(officeManagerLookup.value) ? officeManagerLookup.value.find(c => c.UserID === userID) : officeManagerLookup.value
  return user ? `${user.EmailAddress}` : ""
}

async function getOfficeManagerLookup() {
  await utils.httpRequest(`/Users`, utils.REQUEST.GET, (data) => {
    officeManagerLookup.value = data.filter(item => item.IsActive == true)
  })
}

async function getLookups() {
  await getCountryLookup()
  await getOfficeManagerLookup()
}

watch(selectedCountry, (newValue, oldValue) => {
  resetFilter()
})

watch(selectedIsActive, (newValue, oldValue) => {
  resetFilter()
})

watch(search, (newValue, oldValue) => {
  resetFilter()
})

function resetFilter() {
  isLoading.value = true
  filteredItems.value = lookupItems.value.filter(item => {
    let isActiveMatch = selectedIsActive.value ? item.IsActive === selectedIsActive.value : true
    let isCountryMatch = selectedCountry.value ? item.CountryID === selectedCountry.value : true
    let textSearch = utils.jsonObjectHasText(item, search.value)
    return isActiveMatch && isCountryMatch && textSearch
  })
  isLoading.value = false
}

async function getData() {
  isLoading.value = true
  await utils.httpRequest(`/Static/${lookupName}/`, utils.REQUEST.GET, (data) => {
    lookupItems.value = data
  })
  resetFilter()
}

async function getOneData(dataID) {
  await utils.httpRequest(`/Static/${lookupName}/${dataID}`, utils.REQUEST.GET, (data) => {
    editItem.value = data
  })
}

async function showEdit(dataID) {
  await getOneData(dataID)
  showDialogEditItem.value = true
}

function addNew() {
  editItem.value = {}
  editItem.value.OfficeID = null
  editItem.value.IsActive = true
  showDialogEditItem.value = true
}

async function saveEditItem(dataID) {
  let payload = editItem.value
  let uri = `/Static/${lookupName}`
  if (dataID) {
    uri = uri + `/${dataID}`
  }
  try {
    await utils.httpRequest(uri, utils.REQUEST.POST, (data) => {
    if (!data.Error) {
          updateResultMessage("success|Saved|Saved Successfully", )
        } else {
          throw new Error(data.Error.Message)
        }
    },
    payload)
    await getData()
    showDialogEditItem.value = false
  } catch (e) {
      updateResultMessage("error|Save error|" + e.message)
  }
}

function cancelEditItem() {
  showDialogEditItem.value = false
}

function sendMail(emailAddress) {
  window.open(`mailto:${emailAddress}`)
  updateResultMessage("success|Sending Mail|" + emailAddress)
}

function formatAddress(address) {
  return address ? address.replace(/\n/g, ", ") : "No address stored"
}

function openGoogleMapsUrl(address) {
  const formattedAddress = formatAddress(address)
  const encodedAddress = encodeURIComponent(formatAddress(formattedAddress));
  if (address) {
    window.open(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`, "_blank");
    updateResultMessage("success|Opening Google Maps|" + formattedAddress)
  }
  else {
    updateResultMessage("warning|Opening Google Maps|No address stored")
  }
}

onMounted(async () => {
  emit("updateBreadcrumb", ["Staff List"])
  emit("updateHeader", "Staff List")

  await getData()
  await getLookups()
})

</script>
