<template>
  <v-alert
    rounded="xl"
    density="compact"
    variant="tonal"
    v-for="item in globalNotifications"
    :type="item.NotificationType"
    :title="item.Header"
    :text="item.Description"
  ></v-alert>
</template>
<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import authService from '@/services/authService'
import utils from '@/services/utils'
import { useDate } from 'vuetify'
import { useRouter } from 'vue-router'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const props = defineProps({
  updateMessage: Function,
})


const dateAdapter = useDate()
const router = useRouter()

const today = dateAdapter.toISO(new Date())

const userID = localStorage.getItem("user_id")
const globalNotifications = ref([])

function formatDatePeriod(dt) {
  return utils.formatDatePeriod(dt, true, true, false)
}

function updateResultMessage(message) {
  props.updateMessage(message)
}

async function getGlobalNotifications() {
  if (await authService.isAuthenticated()) {
    await utils.httpRequest(`/GlobalNotifications/Active`, utils.REQUEST.GET, (data) => {
      globalNotifications.value = data
    })
  }
}

onMounted(async () => {
  await getGlobalNotifications()
  setInterval(getGlobalNotifications, 60000)
})
</script>
