<template>
  <div class="ml-3">
    <staffdetail v-if="isAuthorized" :userID="userID" :updateMessage="updateResultMessage" />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import authService from '@/services/authService'
import utils from '@/services/utils'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])

const route = useRoute()
const router = useRouter()
const userID = ref(route.params.id || localStorage.getItem("user_id"))
const userBasic = ref({})
const hasProfile = ref(false)
const isAuthorized = ref(false)

async function getAuthorized() {
  //Is Human Capital User Profile
  let loggedInUserID = localStorage.getItem("user_id")

  //Is Data Returned the same user?
  if (loggedInUserID == userBasic.value.UserID) {
    return true
  }

  //Is Human Capital Admin
  if (await authService.isHumanCapitalAdmin()) {
    return true
  }

  //Is Company Admin
  if (await authService.isCompanyAdmin()) {
    return true
  }

  //Is Human Capital IT
  if (await authService.isHumanCapitalIT()) {
    return true
  }

  //Is Human Capital User Profiles' Management Channel?
  let umr = []
  await utils.httpRequest(`/Users/${userID.value}/ManagementReports`, utils.REQUEST.GET, (data) => {
    umr = data.filter(c => c.UserID == loggedInUserID)
  })
  if (umr.length >= 1) {
    return true
  }

  return false
}

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

async function getUserBasic() {
  await utils.httpRequest(`/Users/${userID.value}/Basic`, utils.REQUEST.GET, (data) => {
    userBasic.value = data
    if (data.UserID) {
      hasProfile.value = true
    }
  })
}

onMounted(async () => {
  if (userID.value !== null && userID !== undefined) {
    await getUserBasic()
  }
  else {
    updateResultMessage("error|No Profile|Cannot find Profile for " + localStorage.getItem("username"))
    router.push({
      name: "no-profile",
      params: { id: localStorage.getItem("username") },
    })
  }

  if (hasProfile.value) {
    isAuthorized.value = await getAuthorized()
    if (isAuthorized.value === false) {
      updateResultMessage("error|Not Authorized|You are not authorized to view Profile ID " + userID.value)
      router.push({
        name: "staff-profile"
      })
    }
    else {
      emit("updateBreadcrumb", ["Staff Profile", userBasic.value.Name])
      emit("updateHeader", userBasic.value.Name)
    }
  }
  else {
    let unknownprofile = (userID.value || localStorage.getItem("username"))
    updateResultMessage("error|No Profile|Cannot find Profile for " + unknownprofile)
    router.push({
      name: "no-profile",
      params: { id: unknownprofile },
    })
  }
})
</script>