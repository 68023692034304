<template><div>
  <v-row>
    <v-col>
      <v-card
        class="mt-0 mb-6 ml-2 mx-4 d-flex flex-column"
        rounded="xl"
        style="overflow-y: auto"
        min-width="100%"
        elevation="4"
        height="240"
      >
        <v-card-title></v-card-title>
        <v-card-text>
          <div class="text-body-2">{{ role.RoleDescription }}</div>
          <div class="mt-6 text-body-1">
            <v-row>
              <v-col cols="2" class="font-weight-bold">Email Address:</v-col>
              <v-col v-if="!!role.EmailAddress">{{ role.EmailAddress }}</v-col>
              <v-col v-else class="font-italic">None</v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            variant="tonal"
            class="ma-3"
            color="primary"
            @click="openDialogEditRole()"
          >Edit Detail</v-btn>
        </v-card-actions>
      </v-card>

      <v-card
        class="mt-0 mb-6 ml-2 d-flex flex-column"
        rounded="xl"
        style="overflow-y: auto"
        min-width="100%"
        elevation="4"
      >
        <v-card-title>Assigned Users</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            variant="tonal"
            class="ma-3"
            color="warning"
            :disabled="!hasChanges"
            @click="cancelRoleUsers()"
          >Cancel Changes</v-btn>
          <v-btn
            variant="tonal"
            class="ma-3"
            color="primary"
            :disabled="!hasChanges"
            @click="saveRoleUsers()"
          >Save Users</v-btn>
        </v-card-actions>
        <v-card-text class="d-flex flex-column">
          <v-row class="mb-4"></v-row>
          <v-row>
            <v-col cols="6">
              <v-list
                variant="text"
                color="primary"
                density="compact"
                v-model:selected="selectedCurrentUser"
                :items="roleUsers"
                :item-title="item => `${item.Name} (${item.CompanyCode})`"
                :item-value="item => item.UserID"
              >
              </v-list>
            </v-col>
            <v-col>
              <v-btn
                size="x-large" 
                color="primary"
                icon=""
                variant="plain"
                density="compact"
                :disabled="!selectedCurrentUser || !(selectedCurrentUser.length > 0)"
                @click="removeFromRoleUsers()"
              >
                <v-icon color="primary" size="x-large" icon="mdi-chevron-right" />
                <v-tooltip
                  activator="parent"
                  location="top"
                >Remove User
                </v-tooltip>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                size="x-large" 
                color="primary"
                icon=""
                variant="plain"
                density="compact"
                :disabled="!selectedAvailableUser"
                @click="addToRoleUsers()"
              >
                <v-icon color="primary" size="x-large" icon="mdi-chevron-left" />
                <v-tooltip
                  activator="parent"
                  location="top"
                >Allow User
                </v-tooltip>
              </v-btn>
            </v-col>
            <v-col cols="5">
              <v-autocomplete
                density="compact"
                persistent-placeholder
                variant="underlined"
                label="Available Users"
                clearable
                :loading="availableUsersLoading"
                v-model="selectedAvailableUser"
                :items="filteredAvailableUsers"
                :item-title="item => `${item.Name} (${item.CompanyCode})`"
                :item-value="item => item.UserID"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

  <v-dialog v-model="dialogEditRole" max-width="750px">
    <v-form>
      <v-card>
        <v-card-title class="text-h5 text-primary">Edit Role Description</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="pa-0 ma-1"><v-text-field
              density="compact"
              persistent-placeholder
              variant="underlined"
              label="Email Address"
              :rules="rulesIsEmailAddress"
              v-model="editRole.EmailAddress"
            /></v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0 ma-1"><v-textarea
              density="compact"
              persistent-placeholder
              variant="underlined"
              no-resize
              rows="4"
              label="Description"
              :rules="rulesIsRequired"
              v-model="editRole.RoleDescription"
            /></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" variant="text" @click="saveEditRole()">Save</v-btn>
          <v-btn color="error" variant="text" @click="closeDialogEditRole()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</div></template>
<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import authService from '@/services/authService'
import utils from '@/services/utils'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const props = defineProps({
  updateMessage: Function,
  roleID: Number
})

var role = ref({})
var roleUsers = ref([])
var selectedCurrentUser = ref(null)

var availableUsers = ref([])
var filteredAvailableUsers = ref([])
var selectedAvailableUser = ref(null)
var availableUsersLoading = ref(true)

const rulesIsRequired = [v => !!v || "Value is required"]
const rulesIsEmailAddress = [ v => (!v || /^.+@.+\..+$/.test(v)) || "Invalid Email Address (user@domain.com)" ]

var dialogEditRole = ref(false)
var editRoleIndex = ref (-1)
var editRole = ref ({
  RoleID: null,
  EmailAddress: null,
  RoleDescription: null,
})

var hasChanges = ref(false)

function updateResultMessage(message) {
  props.updateMessage(message)
}

async function getRoleUsers() {
  await utils.httpRequest(`/Roles/${props.roleID}/Users`, utils.REQUEST.GET, (data) => {
    roleUsers.value = data
  })
}

async function getRole() {
  await utils.httpRequest(`/Roles/${props.roleID}`, utils.REQUEST.GET, (data) => {
    role.value = data
  })
}

async function getUsers() {
  await utils.httpRequest(`/Users`, utils.REQUEST.GET, (data) => {
    availableUsers.value = data.filter(item => (item.IsActive == true))
  })
  filterAvailableUsers()
  availableUsersLoading.value = false
}

async function saveEditRole() {
  try {
    let payload = editRole.value
    await utils.httpRequest(`/Roles/${editRole.value.RoleID}`, utils.REQUEST.POST, (data) => {
        if (data.Error) {
          throw new Error(data.Error.Message)
        }
        role.value = data
      },
      payload
    )
    updateResultMessage("success|Saved Role|Successfully saved role")
  } catch (e) {
    updateResultMessage("error|Could not save Role|" + e.message)
  }

  dialogEditRole.value = false
}

async function saveRoleUsers() {
  try {
    let payload = roleUsers.value
    await utils.httpRequest(`/Roles/${role.value.RoleID}/Users`, utils.REQUEST.POST, (data) => {
        if (data.Error) {
          throw new Error(data.Error.Message)
        }
        roleUsers.value = data
      },
      payload
    )
    updateResultMessage("success|Saved Users|Successfully saved Users for " + role.value.RoleName)
  } catch (e) {
    updateResultMessage("error|Could not save users|" + e.message)
  }

  filterAvailableUsers()
  hasChanges.value = false
}

function addToRoleUsers() {
  let selectedObject = availableUsers.value.filter(item => item.UserID == selectedAvailableUser.value)
  let loadUser = {
    RoleID: role.value.RoleID,
    UserID: selectedObject[0].UserID,
    UserName: selectedObject[0].UserName,
    Name: selectedObject[0].Name,
  }

  roleUsers.value.push(loadUser)

  filterAvailableUsers()  
  hasChanges.value = true
}

function removeFromRoleUsers() {
  let userID = selectedCurrentUser.value[0]
  roleUsers.value = roleUsers.value.filter(item => item.UserID != userID)

  filterAvailableUsers()  
  hasChanges.value = true
}

async function cancelRoleUsers() {
  await getRoleUsers()
  filterAvailableUsers()
  hasChanges.value = false
}

function filterAvailableUsers() {
  const filterUserIDs = roleUsers.value.map(item => item.UserID);
  filteredAvailableUsers.value = availableUsers.value.filter(item => !filterUserIDs.includes(item.UserID))
  selectedAvailableUser.value = null
}

function openDialogEditRole() {
  editRole.value.RoleID = role.value.RoleID
  editRole.value.EmailAddress = role.value.EmailAddress
  editRole.value.RoleDescription = role.value.RoleDescription
  dialogEditRole.value = true
}

function closeDialogEditRole() {
  dialogEditRole.value = false
}

onMounted(async () => {
  await getRole()
  await getRoleUsers()

  await getUsers()
})

</script>