<template>
  <v-app>
    <v-app-bar>
      <v-app-bar-title
        ><img
          alt="Logo"
          cover
          height="50"
          :src="logoSrc"
      />
      </v-app-bar-title>
      <template v-slot:prepend>
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </template>
      <v-menu
        v-if="authenticated"
        open-on-click
        location="start"
      >
        <template v-slot:activator="{ props }">
          <notificationspanel :updateMessage="updateResultMessage" />
          <v-btn
            v-bind="props"
            icon="mdi-dots-vertical"
          >
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            prepend-icon="mdi-account"
            :title="fullname"
            :subtitle="username"
          ></v-list-item>

          <v-list-item
            @click="logout()"
            prepend-icon="mdi-logout"
            title="Logout"
          >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn @click="login()" prepend-icon="mdi-login" v-if="!authenticated">Login</v-btn>
    </v-app-bar>

    <v-main :key="mainKeyRender">
      <v-navigation-drawer
        :key="mainKeyRender"
        v-if="authenticated"
        v-model="drawer"
        temporary
        fluid
      >
        <v-list
          nav
          density="compact"
          color="primary"
        >
          <v-list-subheader>HUMAN CAPITAL</v-list-subheader>
            <v-list-item
              prepend-icon="mdi-medal-outline"
              title="My Profile"
              :to="{ name: 'staff-profile' }"
              value="staff-profile"
            ></v-list-item>
          <v-divider></v-divider>

          <v-list-subheader v-if="isHumanCapitalAdmin || isHumanCapitalIT || isCompanyAdmin">ADMINISTRATION</v-list-subheader>
          <v-list-item
              title="Staff Profiles"
              v-if="isHumanCapitalAdmin || isHumanCapitalIT || isCompanyAdmin"
              :to="{ name: 'staff-list' }"
              value="staff-list"
              prepend-icon="mdi-account-details"
            ></v-list-item>
            <v-list-item
              title="New Staff"
              v-if="isHumanCapitalAdmin || isCompanyAdmin"
              :to="{ name: 'new-staff' }"
              value="new-staff"
              prepend-icon="mdi-account-plus"
            ></v-list-item>
            <v-divider></v-divider>
            <v-list-item
              title="Lookups"
              v-if="isHumanCapitalAdmin"
              :to="{ name: 'lookups' }"
              value="lookups"
              prepend-icon="mdi-list-status"
            ></v-list-item>
            <v-list-item
              title="Roles and Rights"
              v-if="isHumanCapitalAdmin"
              :to="{ name: 'roles' }"
              value="roles"
              prepend-icon="mdi-shield-account"
            ></v-list-item>
            <v-list-item
              title="Open Workflows"
              v-if="isHumanCapitalAdmin || isHumanCapitalIT"
              :to="{ name: 'workflow/list-open' }"
              value="workflow/list-open"
              prepend-icon="mdi-format-list-group"
            ></v-list-item>
            <v-list-item
              title="Global Notifications"
              v-if="isHumanCapitalAdmin || isHumanCapitalIT"
              :to="{ name: 'globalnotifications' }"
              value="globalnotifications"
              prepend-icon="mdi-bell-cog"
            ></v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-container fluid>
        <v-row>
          <v-col>
            <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
          </v-col>
          <v-col class="mr-2" cols="1" align="right">
            <v-btn @click="reloadpage" rounded icon="mdi-refresh"></v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="mr-2">
            <globalnotifications :updateMessage="updateResultMessage" />
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar
        v-model="hasResultMessage"
        :color="resultMessageType"
        vertical>
        <div class="text-subtitle-1 pb-2">{{ resultMessageTitle }}</div>
        <div>{{ resultMessage }}</div>
      </v-snackbar>
      <router-view
        :key="$route.fullPath"
        @updateHeader="updateHeader"
        @updateBreadcrumb="updateBreadcrumb"
        @updateResultMessage="updateResultMessage"
      />
    </v-main>
  </v-app>
</template>

<script setup>
import authService from "@/services/authService"
import themeStore from "@/services/themeStore"
import { useTheme } from "vuetify"
import { ref, onMounted, watch } from "vue"
import { useRouter } from 'vue-router'

const mainKeyRender = ref(0)
const theme = useTheme()
const router = useRouter()
const breadcrumbs = ref([])
const userid = ref()
const username = ref()
const fullname = ref()
const authenticated = ref(false)
const isHumanCapitalAdmin = ref(false)
const isHumanCapitalIT = ref(false)
const isCompanyAdmin = ref(false)
const drawer = ref(false)

var logoSrc = ref(require("./assets/logo.png"))
var localStorageDynamicTheme = ref({})

var hasResultMessage = ref(false)
var resultMessageType = ref(null)
var resultMessageTitle = ref(null)
var resultMessage = ref(null)

function updateHeader(msg) {
  let m = msg ? " - " + msg : ""
  document.title = "Human Capital " + m
}

const updateBreadcrumb = (data) => {
  breadcrumbs.value = data
}

const updateResultMessage = (data) => {
  const parts = data.split("|")
  if (parts.length !== 3) {
    throw new Error("Data does not consist of 3 parts")
  }
  hasResultMessage.value = true
  resultMessageType.value = parts[0]
  resultMessageTitle.value = parts[1]
  resultMessage.value = parts[2]
  const validTypes = ["success", "info", "warning", "error"]
  if (!validTypes.includes(resultMessageType.value)) {
    throw new Error("Invalid result message type")
  }
  let timeout = (resultMessageType.value == "error") ? 10000 : 3000
  setTimeout(() => {
    hasResultMessage.value = false
  }, timeout)
}

function updateUserDetails() {
  fullname.value = localStorage.getItem("fullname")
  username.value = localStorage.getItem("username")
}

async function updateAuthModules() {
  isHumanCapitalAdmin.value = await authService.isHumanCapitalAdmin()
  isHumanCapitalIT.value = await authService.isHumanCapitalIT()
  isCompanyAdmin.value = await authService.isCompanyAdmin()
  mainKeyRender.value += 1;
}

async function setAppConfig() {
  updateUserDetails()
  await getTheme()
  await updateAuthModules()
}

async function login() {
  authenticated.value = await authService.login()
  if (authenticated.value === true) {
    await setAppConfig()
    router.push({
        name: "staff-profile"
      })
  } else {
    router.push({
        name: "not-authenticated"
      })
  }
}

async function logout() {
  await authService.logout()
}

function reloadpage() {
  window.location.reload()
}

function setLogo() {
  if (localStorage.getItem("user_logo")) {
    logoSrc.value = localStorage.getItem("user_logo")
  }
}

function setTheme() {
  if (localStorage.getItem("user_theme")) {
    let dynamicTheme = JSON.parse(localStorage.getItem("user_theme"))
  
    theme.themes.value.dynamicTheme.dark = dynamicTheme.DarkMode
    theme.themes.value.dynamicTheme.colors.background = dynamicTheme.ColourBackground
    theme.themes.value.dynamicTheme.colors.surface = dynamicTheme.ColourSurface
    theme.themes.value.dynamicTheme.colors.primary = dynamicTheme.ColourPrimary
    theme.themes.value.dynamicTheme.colors.secondary = dynamicTheme.ColourSecondary
    theme.themes.value.dynamicTheme.colors.error = dynamicTheme.ColourError
    theme.themes.value.dynamicTheme.colors.info = dynamicTheme.ColourInfo
    theme.themes.value.dynamicTheme.colors.success = dynamicTheme.ColourSuccess
    theme.themes.value.dynamicTheme.colors.warning = dynamicTheme.ColourWarning

    theme.global.name.value = "dynamicTheme"
  }
}

async function getTheme() {
  setTheme()
  setLogo()
  await themeStore.getDynamicTheme()
  localStorageDynamicTheme.value = localStorage.getItem("user_theme")
  logoSrc.value = localStorage.getItem("user_logo")
}

watch (localStorageDynamicTheme, (newValue, oldValue) => {
  setTheme()
})

watch (logoSrc, (newValue, oldValue) => {
  setLogo()
})

onMounted(async () => {
  authenticated.value = await authService.isAuthenticated()
  if (authenticated.value === true) {
    await setAppConfig()
  }
})
</script>
