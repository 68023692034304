<template><div>
  <v-progress-linear
    class="mb-4"
    :active="isLoading"
    color="primary"
    height="4"
    indeterminate
  ></v-progress-linear>
  <v-form ref="staffDetailsForm" validate-on="blur" v-model="isEditUserValid">
    <v-row v-if="userDetails.IsOnboarding">
      <v-banner
        density="comfortable"
        elevation="12"
        icon="mdi-exclamation-thick"
        color="primary"
        bg-color="primary"
      >Staff Profile is in the process of onboarding</v-banner>
    </v-row>
    <v-row v-if="userDetails.IsExiting">
      <v-banner
        density="comfortable"
        elevation="12"
        icon="mdi-exclamation-thick"
        color="error"
        bg-color="error"
      >Staff Profile is in the process of exiting</v-banner>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-card
          class="d-flex flex-column"
          color="primary"
          elevation="4"
          rounded="xl"
          min-height="620">
          <v-card-title
            class="mt-4">
            <v-row>
              <v-col class="d-flex justify-center text-h4 text-secondary font-weight-bold">
                {{ userDetails.PreferredName ? userDetails.PreferredName : userDetails.FirstNames }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center text-h4 text-secondary font-weight-bold">
                {{ userDetails.LastNames }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                {{ userDetails.IsActive ? `Active` : `Not Active` }}
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-divider color="white" class="mb-4"></v-divider>
            <v-tabs
              class="ml-n2 mr-n2 mt-auto"
              density="comfortable"
              direction="vertical"
              v-model="selectedTab">
              <v-tab
                v-for="tab in tabs"
                :value="tab.name"
                variant="text"
                slim
              >
                <v-icon left size="large">{{ tab.icon }}</v-icon>
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </v-card-text>
        </v-card>
        <v-card
          class="mt-4"
          elevation="4"
          rounded="xl"
          height="77"
        >
          <v-card-actions>
            <v-btn
              v-bind="dialogExitUser"
              v-if="userDetails.IsActive && (isAuthorizedAdmin || isAuthorizedCompanyAdmin) && !userDetails.IsExiting && selectedTab !== `MyDashboard`"
              variant="tonal"
              class="ma-3"
              color="error"
              @click="openDialogExitUser()"
            >Exit Staff</v-btn>
            <v-btn
              v-bind="dialogReenableUser"
              v-if="!userDetails.IsActive && (isAuthorizedAdmin || isAuthorizedCompanyAdmin) && !userDetails.IsExiting && selectedTab !== `MyDashboard`"
              variant="tonal"
              class="ma-3"
              color="info"
              @click="openDialogReenableUser()"
            >Re-Activate Staff</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!inEditMode && selectedTab !== `MyDashboard`"
              variant="tonal"
              class="ma-3"
              color="primary"
              @click="editUser()"
            >Edit</v-btn>
            <v-btn
              v-if="inEditMode"
              :disabled="!isEditUserValid"
              variant="tonal"
              class="ma-3"
              color="success"
              @click="saveUser()"
            >Save</v-btn>
            <v-btn
              v-if="inEditMode"
              variant="tonal"
              class="ma-3"
              color="error"
              @click="cancelEditUser()"
            >Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-container
          fluid
          class="mt-n4"
          >
          <v-row>
            <v-col>
              <v-card
                class="mt-0 ml-2 mr-2 d-flex flex-column"
                rounded="xl"
                style="overflow-y: auto"
                elevation="4">
                <v-card-title class="bg-secondary">
                  <staffdetailinvaliddatapanel class="position-absolute top-0 right-0 mr-6 mt-6" ref="invaliddatapanel" :userID="userID" />
                  <v-col>
                    <v-icon :icon="getTabIcon(selectedTab)" size="large"></v-icon>
                    {{ getTabTitle(selectedTab) }}
                  </v-col>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-window
                direction="vertical"
                v-model="selectedTab"
                >
                <v-window-item value="MyDashboard">
                  <v-card
                    class="mb-6 ml-2 mr-2 mt-4 d-flex flex-column"
                    style="overflow-y: auto"
                    elevation="4"
                    rounded="xl"
                    min-height="620">
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <corevalues :updateMessage="updateResultMessage" />
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col cols="4">
                          <linklist :updateMessage="updateResultMessage" />
                        </v-col>
                        <v-col>
                          <birthdaylist :updateMessage="updateResultMessage" />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-window-item>

                <v-window-item value="EmployeeProfile">
                  <v-card
                    class="mt-0 mb-6 ml-2 mr-2 mt-4 d-flex flex-column"
                    style="overflow-y: auto"
                    elevation="4"
                    rounded="xl"
                    min-height="620">
                    <v-card-text>
                      <v-row><v-col></v-col></v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`FirstNames`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`FirstNames`)"
                          label="First Names"
                          v-model="userDetails.FirstNames"
                        />
                        </v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`PreferredName`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`PreferredName`)"
                          label="Preferred Name"
                          v-model="userDetails.PreferredName"
                        />
                        </v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`LastNames`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`LastNames`)"
                          label="Last Names"
                          v-model="userDetails.LastNames"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`UserName`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`UserName`)"
                          :rules="rulesIsEmailAddress"
                          label="Username"
                          v-model="userDetails.UserName"
                        />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`JobTitle`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`JobTitle`)"
                          label="Job Title"
                          v-model="userDetails.JobTitle"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`ManagerID`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`ManagerID`)"
                          label="Manager"
                          v-model="userDetails.ManagerID"
                          :items="lookups.Managers"
                          :item-title="item => `${item.Name} (${item.CompanyCode})`"
                          :item-value="item => item.UserID"
                        >
                          <template v-slot:prepend-inner>
                            <v-btn
                              size="small"
                              color="secondary"
                              icon=""
                              variant="text"
                              density="compact"
                              @click="sendMail(lookups.Managers[0].EmailAddress)"
                            ><v-icon icon="mdi-email" />
                             <v-tooltip
                               activator="parent"
                               location="top"
                             >Send mail to {{ lookups.Managers[0].EmailAddress }}
                             </v-tooltip></v-btn>
                          </template>
                        </v-autocomplete></v-col>
                      </v-row>
                      <v-row>
                        <v-col class="text-primary text-h6">
                          Contact Details
                          <v-divider class="pb-4"></v-divider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`EmailAddress`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`EmailAddress`)"
                          :rules="rulesIsEmailAddress"
                          label="Email Address"
                          v-model="userDetails.EmailAddress"
                        >
                          <template v-slot:prepend-inner>
                            <v-btn
                              size="small"
                              color="secondary"
                              icon=""
                              variant="text"
                              density="compact"
                              @click="sendMail(userDetails.EmailAddress)"
                            ><v-icon icon="mdi-email" />
                           <v-tooltip
                             activator="parent"
                             location="top"
                           >Send mail to {{ userDetails.EmailAddress}}
                           </v-tooltip></v-btn>
                          </template>
                        </v-text-field></v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`CellPhone`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`CellPhone`)"
                          :rules="rulesIsPhoneNumber"
                          placeholder="+27821234567"
                          label="Cellular Phone"
                          v-model="userDetails.CellPhone"
                        /></v-col>
                      </v-row>
                      <v-row>
                      </v-row>
                      <v-row>
                        <v-col class="text-primary text-h6">
                          Employment History
                          <v-divider class="pb-4"></v-divider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><datepicker
                          density="compact"
                          color="secondary"
                          :variant="canEdit(`StartDate`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`StartDate`)"
                          label="Start Date"
                          v-model="userDetails.StartDate"
                        ></datepicker></v-col>
                        <v-col class="pa-0 ma-1"><datepicker
                          density="compact"
                          color="secondary"
                          variant="plain"
                          readonly
                          label="End Date"
                          v-model="userDetails.EndDate"
                        ></datepicker></v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          variant="plain"
                          readonly
                          label="Years of Service"
                          v-model="yearsOfService"
                        ></v-text-field></v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-window-item>

                <v-window-item value="EmploymentDetails">
                  <v-card
                    class="mt-0 mb-6 ml-2 mr-2 mt-4 d-flex flex-column"
                    style="overflow-y: auto"
                    elevation="4"
                    rounded="xl"
                    min-height="620">
                    <v-card-text>
                      <v-row><v-col></v-col></v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1">
                          <v-text-field
                            density="compact"
                            persistent-placeholder
                            :variant="canEdit(`EmployeeNumber`) ? 'underlined': 'plain'"
                            :readonly="!canEdit(`EmployeeNumber`)"
                            readonly
                            label="Employee Number"
                            v-model="userDetails.EmployeeNumber"
                          >
                          <template v-slot:prepend>
                            <v-btn
                              class="mt-n4"
                              variant="text"
                              size="small"
                              color="secondary"
                              icon=""
                            ><v-icon icon="mdi-information" />
                              <v-tooltip
                                activator="parent"
                                location="top"
                              >Employee Number is saved in Payroll. It cannot be saved here.
                              </v-tooltip>
                            </v-btn>
                          </template>
                          </v-text-field>
                        </v-col>
                        <v-col class="pa-0 ma-1"></v-col>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`EmployeeTypeID`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`EmployeeTypeID`)"
                          label="Employment Type"
                          v-model="userDetails.EmployeeTypeID"
                          :items="lookups.EmployeeTypes"
                          :item-title="item => item.EmployeeTypeName"
                          :item-value="item => item.EmployeeTypeID"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          auto-select-first
                          :variant="canEdit(`CountryID`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`CountryID`)"
                          label="Country"
                          v-model="userDetails.CountryID"
                          :items="lookups.Countries"
                          :item-title="item => `${item.CountryName} (${item.CountryCode})`"
                          :item-value="item => item.CountryID"
                        /></v-col>
                      </v-row>
                      <v-row>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`CompanyID`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`CompanyID`)"
                          label="Company"
                          v-model="userDetails.CompanyID"
                          :items="lookups.Companies"
                          :item-title="item => `${item.CompanyName} (${item.CompanyCode})`"
                          :item-value="item => item.CompanyID"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`SiloID`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`SiloID`)"
                          label="Silo"
                          v-model="userDetails.SiloID"
                          :items="lookups.Silos"
                          :item-title="item => `${item.SiloName} (${item.SiloCode})`"
                          :item-value="item => item.SiloID"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`DepartmentID`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`DepartmentID`)"
                          label="Department"
                          v-model="userDetails.DepartmentID"
                          :items="lookups.Departments"
                          :item-title="item => `${item.DepartmentName} (${item.DepartmentCode})`"
                          :item-value="item => item.DepartmentID"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`OfficeID`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`OfficeID`)"
                          label="Office"
                          v-model="userDetails.OfficeID"
                          :items="lookups.Offices"
                          :item-title="item => `${item.OfficeName} (${getCountryCode(item.CountryID)})`"
                          :item-value="item => item.OfficeID"
                        /></v-col>
                      </v-row>
                      <v-row><v-col class="pa-0 ma-1 text-primary text-h6">
                        <v-btn
                          class="mt-n1"
                          variant="text"
                          size="small"
                          color="secondary"
                          icon=""
                        ><v-icon icon="mdi-information" />
                          <v-tooltip
                            activator="parent"
                            location="top"
                          >Bank Account Details are saved in Payroll. They cannot be saved here.
                          </v-tooltip>
                        </v-btn>
                        Bank Account Details
                        <v-divider class="pb-4"></v-divider>
                      </v-col></v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          variant="plain"
                          readonly
                          label="Bank"
                          v-model="userBankAccount.BankName"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          variant="plain"
                          readonly
                          label="Bank Account Number"
                          v-model="userBankAccount.AccountNumber"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          variant="plain"
                          readonly
                          label="Bank Account Name"
                          v-model="userBankAccount.AccountName"
                        /></v-col>
                        <v-col class="pa-0 ma-1"></v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          variant="plain"
                          readonly
                          label="Branch Name"
                          v-model="userBankAccount.BranchName"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          variant="plain"
                          readonly
                          label="Branch Code"
                          v-model="userBankAccount.BranchCode"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          variant="plain"
                          readonly
                          label="Bank Account Type"
                          v-model="userBankAccount.BankAccountTypeID"
                          :items="lookups.BankAccountTypes"
                          :item-title="item => item.BankAccountTypeName"
                          :item-value="item => item.BankAccountTypeID"
                        /></v-col>
                        <v-col class="pa-0 ma-1"></v-col>
                      </v-row>
                      <v-row><v-col class="pa-0 ma-1 text-primary text-h6">
                        <v-btn
                          class="mt-n1"
                          variant="text"
                          size="small"
                          color="secondary"
                          icon=""
                        ><v-icon icon="mdi-information" />
                          <v-tooltip
                            activator="parent"
                            location="top"
                          >Tax Details are saved in Payroll. They cannot be saved here.
                          </v-tooltip>
                        </v-btn>
                        Tax Details
                        <v-divider class="pb-4"></v-divider>
                      </v-col></v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          variant="plain"
                          readonly
                          label="Tax Number"
                          v-model="userTax.TaxNumber"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          variant="plain"
                          readonly
                          label="Revenue Office"
                          v-model="userTax.RevenueOffice"
                        /></v-col>
                        <v-col class="pa-0 ma-1"></v-col>
                        <v-col class="pa-0 ma-1"></v-col>
                      </v-row>
                      <v-row><v-col class="pa-0 ma-1 text-primary text-h6">Compensation
                        <v-divider class="pb-4"></v-divider>
                      </v-col></v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`CompensationTypeID`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`CompensationTypeID`)"
                          label="Compensation Type"
                          v-model="userCompensation.CompensationTypeID"
                          :items="lookups.CompensationTypes"
                          :item-title="item => item.CompensationTypeName"
                          :item-value="item => item.CompensationTypeID"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`CurrencyID`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`CurrencyID`)"
                          label="Currency"
                          v-model="userCompensation.CurrencyID"
                          :items="lookups.Currencies"
                          :item-title="item => `${item.CurrencyName} (${item.CurrencyCode})`"
                          :item-value="item => item.CurrencyID"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-text-field v-if="canSeeRemuneration && (userCompensation.AnnualRemuneration != -999)"
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`AnnualRemuneration`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`AnnualRemuneration`)"
                          label="Annual Remuneration"
                          :type="canEdit(`AnnualRemuneration`) ? 'number': 'text'"
                          v-model="userCompensation.AnnualRemuneration"
                          :value="canEdit(`AnnualRemuneration`) ? userCompensation.AnnualRemuneration : formattedAnnualRemuneration"
                        />
                        <v-btn
                          v-if="!canSeeRemuneration && (userCompensation.AnnualRemuneration != -999)"
                          color="primary"
                          variant="outlined"
                          class="mt-n2 mb-n4"
                          prepend-icon="mdi-eye"
                          @click="canSeeRemuneration = true"
                        >Show Remuneration</v-btn>
                        </v-col>
                        <v-col class="pa-0 ma-1"></v-col>
                      </v-row>
                      <v-row>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-window-item>
              
                <v-window-item value="PersonalDetails">
                  <v-card
                    class="mt-0 mb-6 ml-2 mr-2 mt-4 d-flex flex-column"
                    style="overflow-y: auto"
                    elevation="4"
                    rounded="xl"
                    min-height="620">
                    <v-card-text>
                      <v-row><v-col></v-col></v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><datepicker
                          density="compact"
                          color="secondary"
                          :variant="canEdit(`DateOfBirth`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`DateOfBirth`)"
                          label="Date Of Birth"
                          v-model="userDetails.DateOfBirth"
                        ></datepicker></v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`IDNumber`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`IDNumber`)"
                          label="ID Number"
                          v-model="userDetails.IDNumber"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          auto-select-first
                          :variant="canEdit(`GenderID`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`GenderID`)"
                          label="Gender"
                          v-model="userDetails.GenderID"
                          :items="lookups.Genders"
                          :item-title="item => item.GenderName"
                          :item-value="item => item.GenderID"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          auto-select-first
                          :variant="canEdit(`MaritalStatusID`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`MaritalStatusID`)"
                          label="Marital Status"
                          v-model="userDetails.MaritalStatusID"
                          :items="lookups.MaritalStatuses"
                          :item-title="item => `${item.MaritalStatusName}`"
                          :item-value="item => item.MaritalStatusID"
                        /></v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><v-textarea
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`Address1`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`Address1`)"
                          no-resize
                          rows="4"
                          label="Street Address"
                          v-model="userDetails.Address1"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-textarea
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`Address2`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`Address2`)"
                          no-resize
                          rows="4"
                          label="Postal Address"
                          v-model="userDetails.Address2"
                        /></v-col>
                      </v-row>
                      <v-row><v-col class="pa-0 ma-1 text-primary text-h6">Medical Details
                        <v-divider class="pb-4"></v-divider>
                      </v-col></v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><v-switch
                          class="ml-4 mt-n2"
                          density="compact"
                          color="primary"
                          :readonly="!canEdit(`IsCompanyMedicalAid`)"
                          label="Company Medical Aid"
                          v-model="userMedical.IsCompanyMedicalAid"
                        ></v-switch></v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`MedicalAidName`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`MedicalAidName`)"
                          label="Medical Aid Name"
                          v-model="userMedical.MedicalAidName"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`MedicalAidPlan`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`MedicalAidPlan`)"
                          label="Medical Aid Plan"
                          v-model="userMedical.MedicalAidPlan"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`MedicalAidNumber`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`MedicalAidNumber`)"
                          label="Medical Aid Number"
                          v-model="userMedical.MedicalAidNumber"
                        /></v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`DoctorName`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`DoctorName`)"
                          label="Doctor's Name"
                          v-model="userMedical.DoctorName"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`DoctorTelephone`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`DoctorTelephone`)"
                          :rules="rulesIsPhoneNumber"
                          label="Doctor's Telephone"
                          v-model="userMedical.DoctorTelephone"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`NumberOfDependents`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`NumberOfDependents`)"
                          type="number"
                          label="Number of Dependents"
                          v-model="userMedical.NumberOfDependants"
                        /></v-col>
                        <v-col class="pa-0 ma-1"></v-col>
                      </v-row>
                      <v-row><v-col class="pa-0 ma-1 text-primary text-h6">Next Of Kin
                          <v-divider class="pb-4"></v-divider>
                          <div class="d-flex justify-end">
                            <v-btn
                              v-if="canEdit(`NextOfKin`)"
                              variant="tonal"
                              class="ma-3"
                              color="primary"
                              @click="openDialogEditNextOfKin()"
                            >Add New Next Of Kin</v-btn>
                          </div>
                        </v-col>
                      </v-row>
                      <v-data-table
                        density="compact"
                        :headers="userNextOfKinHeaders"
                        :items="userNextOfKin"
                        items-per-page="-1"
                        hide-default-footer="true">
                        <template v-slot:item.actions="{ item }">
                          <div>
                            <v-btn
                              v-if="inEditMode"
                              size="small"
                              color="primary"
                              icon=""
                              variant="plain"
                              density="compact"
                              @click="openDialogEditNextOfKin(item)"
                            ><v-icon icon="mdi-pencil" />
                              <v-tooltip
                                activator="parent"
                                location="top"
                              >Edit {{ item.NextOfKinName }}
                              </v-tooltip>
                            </v-btn>
                            <v-btn
                              v-if="inEditMode"
                              size="small"
                              color="primary"
                              icon=""
                              variant="plain"
                              density="compact"
                              @click="openDialogDeleteNextOfKin(item)"
                            ><v-icon color="error" size="small" icon="mdi-delete" />
                              <v-tooltip
                                activator="parent"
                                location="top"
                              >Delete {{ item.NextOfKinName }}
                              </v-tooltip>
                            </v-btn>
                          </div>
                        </template>
                        <template v-slot:bottom> </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-window-item>

                <v-window-item value="TravelDetails">
                  <v-card
                    class="mt-0 mb-6 ml-2 mr-2 mt-4 d-flex flex-column"
                    style="overflow-y: auto"
                    elevation="4"
                    rounded="xl"
                    min-height="620">
                    <v-card-text>
                      <v-row><v-col></v-col></v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`Passport1Name`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`Passport1Name`)"
                          label="Name on 1st Passport"
                          v-model="userTravel.Passport1Name"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`Passport1Number`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`Passport1Number`)"
                          label="1st Passport Number"
                          v-model="userTravel.Passport1Number"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          auto-select-first
                          :variant="canEdit(`Passport1CountryID`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`Passport1CountryID`)"
                          label="1st Passport Country"
                          v-model="userTravel.Passport1CountryID"
                          :items="lookups.AllCountries"
                          :item-title="item => `${item.CountryName} (${item.CountryCode})`"
                          :item-value="item => item.CountryID"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><datepicker
                          density="compact"
                          color="secondary"
                          :variant="canEdit(`Passport1Expiry`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`Passport1Expiry`)"
                          label="1st Passport Expiry Date"
                          v-model="userTravel.Passport1Expiry"
                        ></datepicker></v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`Passport2Name`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`Passport2Name`)"
                          label="Name on 2nd Passport"
                          v-model="userTravel.Passport2Name"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-text-field
                          density="compact"
                          persistent-placeholder
                          :variant="canEdit(`Passport2Number`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`Passport2Number`)"
                          label="2nd Passport Number"
                          v-model="userTravel.Passport2Number"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          auto-select-first
                          :variant="canEdit(`Passport2CountryID`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`Passport2CountryID`)"
                          label="2nd Passport Country"
                          v-model="userTravel.Passport2CountryID"
                          :items="lookups.AllCountries"
                          :item-title="item => `${item.CountryName} (${item.CountryCode})`"
                          :item-value="item => item.CountryID"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><datepicker
                          density="compact"
                          color="secondary"
                          :variant="canEdit(`Passport2Expiry`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`Passport2Expiry`)"
                          label="2nd Passport Expiry Date"
                          v-model="userTravel.Passport2Expiry"
                        ></datepicker></v-col>
                      </v-row>
                      <v-row><v-col class="pa-0 ma-1 text-primary text-h6">Travel Details
                        <v-divider class="pb-4"></v-divider>
                      </v-col></v-row>
                      <v-row>
                        <v-col class="pa-0 ma-1"><v-switch
                          class="ml-4 mt-n2"
                          density="compact"
                          color="primary"
                          :readonly="!canEdit(`IsTravelSystemUser`)"
                          label="Travel System Access"
                          v-model="userTravel.IsTravelSystemUser"
                        ></v-switch></v-col>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          auto-select-first
                          :variant="canEdit(`SeatingTypeID`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`SeatingTypeID`)"
                          label="Seating Preference"
                          v-model="userTravel.SeatingTypeID"
                          :items="lookups.SeatingTypes"
                          :item-title="item => `${item.SeatingTypeName} (${item.SeatingTypeCode})`"
                          :item-value="item => item.SeatingTypeID"
                        /></v-col>
                        <v-col class="pa-0 ma-1"><v-autocomplete
                          density="compact"
                          persistent-placeholder
                          auto-select-first
                          :variant="canEdit(`MealTypeID`) ? 'underlined': 'plain'"
                          :readonly="!canEdit(`MealTypeID`)"
                          label="Meal Preference"
                          v-model="userTravel.MealTypeID"
                          :items="lookups.MealTypes"
                          :item-title="item => `${item.MealTypeName} (${item.MealTypeCode})`"
                          :item-value="item => item.MealTypeID"
                        /></v-col>
                        <v-col class="pa-0 ma-1"></v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-window-item>

                <v-window-item value="Documents">
                  <v-card
                    class="mt-0 mb-6 ml-2 mr-2 mt-4 d-flex flex-column"
                    style="overflow-y: auto"
                    elevation="4"
                    rounded="xl"
                    min-height="620">
                    <v-card-text>
                      <staffdocuments :userID="userDetails.UserID" :companyID="userDetails.CompanyID" :updateMessage="updateMessage" />
                    </v-card-text>
                  </v-card>
                </v-window-item>

                <v-window-item value="StaffReports">
                  <v-card
                    class="mt-0 mb-6 ml-2 mr-2 mt-4 d-flex flex-column"
                    style="overflow-y: auto"
                    elevation="4"
                    rounded="xl"
                    min-height="620">
                    <v-card-text>
                      <v-row><v-col>
                        <v-expansion-panels
                          v-model="selectedStaffReport"
                          variant="inset"
                        >
                          <v-expansion-panel value="DirectReports" v-if="userStaffReportsDirect.length > 0">
                            <v-expansion-panel-title class="text-primary text-h6">Direct Reports</v-expansion-panel-title>
                            <v-expansion-panel-text>
                              <v-data-table
                                density="compact"
                                :headers="userStaffReportsHeaders"
                                :items="userStaffReportsDirect"
                                items-per-page="-1"
                                hide-default-footer="true">
                                <template v-slot:item.Name="{ item }">
                                  <v-icon
                                  v-if="item.IsActive"
                                  size="small"
                                  icon="mdi-check"
                                  color="success"
                                ></v-icon>
                                <v-icon
                                  v-if="! item.IsActive"
                                  size="small"
                                  icon="mdi-cancel"
                                  color="error"
                                ></v-icon>
                                {{  item.Name }}
                                </template>
                                <template v-slot:item.EmailAddress="{ item }">
                                  <v-btn
                                    variant="text"
                                    size="small"
                                    color="secondary"
                                    icon=""
                                    @click="sendMail(item.EmailAddress)"
                                  ><v-icon icon="mdi-email" />
                                    <v-tooltip
                                      activator="parent"
                                      location="top"
                                    >Send mail to {{ item.EmailAddress }}
                                    </v-tooltip>
                                  </v-btn>
                                  {{ item.EmailAddress }}
                                </template>
                                <template v-slot:item.actions="{ item }">
                                  <div>
                                    <v-btn
                                      color="primary"
                                      icon=""
                                      variant="plain"
                                      density="compact"
                                      @click="routeToUserProfile(item.UserID)"
                                    ><v-icon size="small" icon="mdi-eye" />
                                      <v-tooltip
                                        activator="parent"
                                        location="top"
                                      >View {{  item.Name }}
                                      </v-tooltip>
                                    </v-btn>
                                  </div>
                                </template>
                                <template v-slot:bottom> </template>
                              </v-data-table>
                            </v-expansion-panel-text>
                          </v-expansion-panel>

                          <v-expansion-panel value="IndirectReports" v-if="userStaffReportsIndirect.length > 0">
                            <v-expansion-panel-title class="text-primary text-h6">Indirect Reports</v-expansion-panel-title>
                            <v-expansion-panel-text>
                              <v-data-table
                                density="compact"
                                :headers="userStaffReportsHeaders"
                                :items="userStaffReportsIndirect"
                                items-per-page="-1"
                                hide-default-footer="true"
                                no-data-text="No Direct Reports">
                                <template v-slot:item.Name="{ item }">
                                  <v-icon
                                  v-if="item.IsActive"
                                  size="small"
                                  icon="mdi-check"
                                  color="success"
                                ></v-icon>
                                <v-icon
                                  v-if="! item.IsActive"
                                  size="small"
                                  icon="mdi-cancel"
                                  color="error"
                                ></v-icon>
                                {{  item.Name }}
                                </template>
                                <template v-slot:item.EmailAddress="{ item }">
                                  <v-btn
                                    variant="text"
                                    size="small"
                                    color="secondary"
                                    icon=""
                                    @click="sendMail(item.EmailAddress)"
                                    ><v-icon icon="mdi-email" />
                                    <v-tooltip
                                      activator="parent"
                                      location="top"
                                    >Send mail to {{ item.EmailAddress }}
                                    </v-tooltip>
                                  </v-btn>
                                  {{ item.EmailAddress }}
                                </template>
                                <template v-slot:item.actions="{ item }">
                                  <div>
                                    <v-btn
                                      color="primary"
                                      icon=""
                                      variant="plain"
                                      density="compact"
                                      @click="routeToUserProfile(item.UserID)"
                                    ><v-icon size="small" icon="mdi-eye" />
                                      <v-tooltip
                                        activator="parent"
                                        location="top"
                                      >View {{  item.Name }}
                                      </v-tooltip>
                                    </v-btn>
                                  </div>
                                </template>
                                <template v-slot:bottom> </template>
                              </v-data-table>
                            </v-expansion-panel-text>
                          </v-expansion-panel>

                          <v-expansion-panel value="CoWorkerReports" v-if="userCoworkers.length > 0">
                            <v-expansion-panel-title class="text-primary text-h6">Co-workers</v-expansion-panel-title>
                            <v-expansion-panel-text>
                              <v-data-table
                                density="compact"
                                :headers="userStaffReportsHeaders"
                                :items="userCoworkers"
                                items-per-page="-1"
                                hide-default-footer="true">
                                <template v-slot:item.Name="{ item }">
                                  <v-icon
                                  v-if="item.IsActive"
                                  size="small"
                                  icon="mdi-check"
                                  color="success"
                                ></v-icon>
                                <v-icon
                                  v-if="! item.IsActive"
                                  size="small"
                                  icon="mdi-cancel"
                                  color="error"
                                ></v-icon>
                                {{  item.Name }}
                                </template>
                                <template v-slot:item.EmailAddress="{ item }">
                                  <v-btn
                                    variant="text"
                                    color="secondary"
                                    size="small"
                                    icon=""
                                    @click="sendMail(item.EmailAddress)"
                                    ><v-icon icon="mdi-email" />
                                    <v-tooltip
                                      activator="parent"
                                      location="top"
                                    >Send mail to {{ item.EmailAddress }}
                                    </v-tooltip>
                                  </v-btn>
                                  {{ item.EmailAddress }}
                                </template>
                                <template v-slot:item.actions="{ item }">
                                  <div>
                                    <v-btn
                                      color="primary"
                                      icon=""
                                      variant="plain"
                                      density="compact"
                                      @click="routeToUserProfile(item.UserID)"
                                    ><v-icon size="small" icon="mdi-eye" />
                                      <v-tooltip
                                        activator="parent"
                                        location="top"
                                      >View {{  item.Name }}
                                      </v-tooltip>
                                    </v-btn>
                                  </div>
                                </template>
                                <template v-slot:bottom> </template>
                              </v-data-table>
                            </v-expansion-panel-text>
                          </v-expansion-panel>

                          <v-expansion-panel value="ManagementReports" v-if="userManagementReports.length > 0">
                            <v-expansion-panel-title class="text-primary text-h6">Management Reports</v-expansion-panel-title>
                            <v-expansion-panel-text>
                              <v-data-table
                                density="compact"
                                :headers="userManagementReportsHeaders"
                                :items="userManagementReports"
                                items-per-page="-1"
                                hide-default-footer="true">
                                <template v-slot:item.Name="{ item }">
                                  <v-icon
                                    size="small"
                                    icon="mdi-arrow-down-right-bold"
                                    color="primary"
                                  ></v-icon>
                                  <v-icon
                                    v-if="item.IsActive"
                                    size="small"
                                    icon="mdi-check"
                                    color="success"
                                  ></v-icon>
                                  <v-icon
                                    v-if="! item.IsActive"
                                    size="small"
                                    icon="mdi-cancel"
                                    color="error"
                                  ></v-icon>
                                  {{  item.Name }}
                                </template>
                                <template v-slot:item.EmailAddress="{ item }">
                                  <v-btn
                                    variant="text"
                                    color="secondary"
                                    size="small"
                                    icon=""
                                    @click="sendMail(item.EmailAddress)"
                                    ><v-icon icon="mdi-email" />
                                    <v-tooltip
                                      activator="parent"
                                      location="top"
                                    >Send mail to {{ item.EmailAddress }}
                                    </v-tooltip>
                                  </v-btn>
                                  {{ item.EmailAddress }}
                                </template>
                                <template v-slot:item.actions="{ item }">
                                  <div>
                                    <v-btn
                                      color="primary"
                                      icon=""
                                      variant="plain"
                                      density="compact"
                                      @click="routeToUserProfile(item.UserID)"
                                    ><v-icon size="small" icon="mdi-eye" />
                                      <v-tooltip
                                        activator="parent"
                                        location="top"
                                      >View {{ item.Name }}
                                      </v-tooltip>
                                    </v-btn>
                                  </div>
                                </template>
                                <template v-slot:bottom> </template>
                              </v-data-table>
                            </v-expansion-panel-text>
                          </v-expansion-panel>

                          <v-expansion-panel value="PastReports" v-if="userStaffReportsPast.length > 0">
                            <v-expansion-panel-title class="text-primary text-h6">Past Reports</v-expansion-panel-title>
                            <v-expansion-panel-text>
                              <v-data-table
                                density="compact"
                                :headers="userStaffReportsHeaders"
                                :items="userStaffReportsPast"
                                items-per-page="-1"
                                hide-default-footer="true"
                                no-data-text="No Direct Reports">
                                <template v-slot:item.Name="{ item }">
                                  <v-icon
                                  v-if="item.IsActive"
                                  size="small"
                                  icon="mdi-check"
                                  color="success"
                                ></v-icon>
                                <v-icon
                                  v-if="! item.IsActive"
                                  size="small"
                                  icon="mdi-cancel"
                                  color="error"
                                ></v-icon>
                                {{  item.Name }}
                                </template>
                                <template v-slot:item.EmailAddress="{ item }">
                                  <v-btn
                                    variant="text"
                                    color="secondary"
                                    size="small"
                                    icon=""
                                    @click="sendMail(item.EmailAddress)"
                                    ><v-icon icon="mdi-email" />
                                    <v-tooltip
                                      activator="parent"
                                      location="top"
                                    >Send mail to {{ item.EmailAddress }}
                                    </v-tooltip>
                                  </v-btn>
                                  {{ item.EmailAddress }}
                                </template>
                                <template v-slot:item.actions="{ item }">
                                  <div>
                                    <v-btn
                                      color="primary"
                                      icon=""
                                      size="small"
                                      variant="plain"
                                      density="compact"
                                      @click="routeToUserProfile(item.UserID)"
                                    ><v-icon size="small" icon="mdi-eye" />
                                      <v-tooltip
                                        activator="parent"
                                        location="top"
                                      >View {{  item.Name }}
                                      </v-tooltip>
                                    </v-btn>
                                  </div>
                                </template>
                                <template v-slot:bottom> </template>
                              </v-data-table>
                            </v-expansion-panel-text>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col></v-row>
                    </v-card-text>
                  </v-card>
                </v-window-item>

                <v-window-item value="SystemRoles">
                  <v-card
                    class="mt-0 mb-6 ml-2 mr-2 mt-4 d-flex flex-column"
                    style="overflow-y: auto"
                    elevation="4"
                    rounded="xl"
                    min-height="620">
                    <v-card-text>
                      <v-row><v-col class="pa-0 ma-1 text-primary text-h6">Human Capital
                        <v-divider class="pb-4"></v-divider>
                      </v-col></v-row>
                      <v-row class="mb-4" v-if=!!userRolesHumanCapital>
                        <v-chip v-for="r in userRolesHumanCapital"
                          class="ml-2 mr-2 mb-2"
                          color="primary"
                          label>
                          <v-icon icon="mdi-label" start></v-icon>
                          <v-tooltip
                            activator="parent"
                            location="top"
                          >{{ r.RoleDescription }}
                          </v-tooltip>
                          {{ r.RoleName }}
                        </v-chip>
                        <v-chip v-if="userRolesHumanCapital.length === 0"
                          class="ml-2 mr-2"
                          color="primary"
                          label>
                          <v-icon icon="mdi-label" start></v-icon>
                          No Roles Assigned
                        </v-chip>
                      </v-row>
                      <v-row><v-col class="pa-0 ma-1 text-primary text-h6">Performance Review
                        <v-divider class="pb-4"></v-divider>
                      </v-col></v-row>
                      <v-row class="mb-4">
                        <v-chip v-for="r in userRolesPerformance"
                          class="ml-2 mr-2 mb-2"
                          color="primary"
                          label>
                          <v-icon icon="mdi-label" start></v-icon>
                          <v-tooltip
                            activator="parent"
                            location="top"
                          >{{ r.RoleDescription }}
                          </v-tooltip>
                          {{ r.RoleName }}
                        </v-chip>
                        <v-chip v-if="userRolesPerformance.length === 0"
                          class="ml-2 mr-2"
                          label>
                          <v-icon icon="mdi-label" start></v-icon>
                          No Roles Assigned
                        </v-chip>
                      </v-row>
                      <v-row><v-col class="pa-0 ma-1 text-primary text-h6">Compensation
                        <v-divider class="pb-4"></v-divider>
                      </v-col></v-row>
                      <v-row class="mb-4">
                        <v-chip v-for="r in userRolesCompensation"
                          class="ml-2 mr-2 mb-2"
                          color="primary"
                          label>
                          <v-icon icon="mdi-label" start></v-icon>
                          <v-tooltip
                            activator="parent"
                            location="top"
                          >{{ r.RoleDescription }}
                          </v-tooltip>
                          {{ r.RoleName }}
                        </v-chip>
                        <v-chip v-if="userRolesCompensation.length === 0"
                          class="ml-2 mr-2 mb-2"
                          label>
                          <v-icon icon="mdi-label" start></v-icon>
                          No Roles Assigned
                        </v-chip>
                      </v-row>
                      <v-row><v-col class="pa-0 ma-1 text-primary text-h6">Admin for Companies
                        <v-divider class="pb-4"></v-divider>
                      </v-col></v-row>
                      <v-row class="mb-4">
                        <v-chip v-for="r in userCompanyAdmin"
                          class="ml-2 mr-2 mb-2"
                          :color="getCompanyAdminColour(r)"
                          label>
                          <v-icon icon="mdi-label" start></v-icon>
                          <v-tooltip
                            activator="parent"
                            location="top"
                          >{{ r.CompanyName }}
                          </v-tooltip>
                          {{ r.CompanyCode }}
                        </v-chip>
                        <v-chip v-if="userCompanyAdmin.length === 0"
                          class="ml-2 mr-2"
                          label>
                          <v-icon icon="mdi-label" start></v-icon>
                          No Companies Assigned
                        </v-chip>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-window-item>
              </v-window>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
    </v-row>
  </v-form>

  <v-dialog v-model="dialogEditNextOfKin" max-width="750px">
    <v-form>
      <v-card>
        <v-card-title class="text-h5 text-primary">Edit Next of Kin</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="pa-0 ma-1"><v-text-field
              density="compact"
              persistent-placeholder
              variant="underlined"
              label="Next of Kin's Name"
              :rules="rulesIsRequired"
              v-model="editNextOfKin.NextOfKinName"
            /></v-col>
            <v-col class="pa-0 ma-1"><v-text-field
              density="compact"
              persistent-placeholder
              variant="underlined"
              label="Relationship"
              :rules="rulesIsRequired"
              v-model="editNextOfKin.NextOfKinRelationship"
            /></v-col>
            <v-col class="pa-0 ma-1"><v-text-field
              density="compact"
              persistent-placeholder
              variant="underlined"
              label="Telephone"
              :rules="rulesIsRequired && rulesIsPhoneNumber"
              v-model="editNextOfKin.NextOfKinTelephone"
            /></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" variant="text" @click="saveEditNextOfKin()">Save</v-btn>
          <v-btn color="error" variant="text" @click="closeDialogEditNextOfKin()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>

  <v-dialog v-model="dialogDeleteNextOfKin" max-width="430px">
    <v-card>
        <v-card-title class="ml-4">Are you sure you want to delete this item?</v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" variant="text" @click="deleteNextOfKinConfirm">OK</v-btn>
          <v-btn color="error" variant="text" @click="closeDialogDeleteNextOfKin">Cancel</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  <v-dialog v-model="dialogExitUser" max-width="750px">
    <v-form>
      <v-card>
        <v-card-title class="text-h5 text-primary">Exit User</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="pa-0 ma-1"><v-switch
              density="compact"
              color="primary"
              label="Create Exit Workflows?"
              v-model="exitUser.CreateExitWorkflows"
            ></v-switch></v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0 ma-1"><datepicker
              density="compact"
              color="secondary"
              variant="underlined"
              label="Proposed End Date"
              v-model="exitUser.ProposedEndDate"
            ></datepicker></v-col>
            <v-col class="pa-0 ma-1">
              <v-autocomplete
                density="compact"
                persistent-placeholder
                variant="underlined"
                label="Manager"
                v-model="exitUser.ManagerID"
                :rules="rulesIsRequired"
                :items="lookups.Managers"
                :item-title="item => `${item.Name} (${item.CompanyCode})`"
                :item-value="item => item.UserID"
              /></v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0 ma-1"><v-textarea
              density="compact"
              persistent-placeholder
              variant="underlined"
              no-resize
              rows="4"
              label="Special Instructions"
              v-model="exitUser.SpecialRequests"
            /></v-col>
          </v-row>
          <v-row>
            <v-col>
            <v-alert
              v-if="!exitUser.CreateExitWorkflows"
              density="compact"
              color="warning"
              variant="tonal"
              icon="mdi-alert"
              text="If you don't create Exit Workflows, the user will be deactivated immediately without notification to other parties" />
            <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" variant="text" @click="saveExitUser()">Save</v-btn>
          <v-btn color="error" variant="text" @click="closeDialogExitUser()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>

  <v-dialog v-model="dialogReenableUser" max-width="750px">
    <v-form>
      <v-card>
        <v-card-title class="text-h5 text-primary">Re-Activate Staff</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="pa-0 ma-1"><datepicker
              density="compact"
              color="secondary"
              variant="underlined"
              label="Proposed Start Date"
              v-model="reenableUser.ProposedStartDate"
            ></datepicker></v-col>
            <v-col class="pa-0 ma-1">
              <v-autocomplete
                density="compact"
                persistent-placeholder
                variant="underlined"
                label="Manager"
                v-model="reenableUser.ManagerID"
                :rules="rulesIsRequired"
                :items="lookups.Managers"
                :item-title="item => `${item.Name} (${item.CompanyCode})`"
                :item-value="item => item.UserID"
              /></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" variant="text" @click="saveReenableUser()">Save</v-btn>
          <v-btn color="error" variant="text" @click="closeDialogReenableUser()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</div></template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import authService from '@/services/authService'
import utils from '@/services/utils'
import { useDate } from 'vuetify'

const props = defineProps({
  userID: [String, Number],
  updateMessage: Function,
})

const isAuthorizedAdmin = ref(false)
const isAuthorizedIT = ref(false)
const isAuthorizedUserManager = ref(false)
const isAuthorizedUser = ref(false)
const isAuthorizedCompanyAdmin = ref(false)

const dateAdapter = useDate()
const router = useRouter()

const today = dateAdapter.toISO(new Date())

const invaliddatapanel = ref(null)

const selectedTab = ref("MyDashboard")
const tabs = ref([
  { name: "MyDashboard", title: "My Dashboard", icon: "mdi-view-dashboard" },
  { name: "EmployeeProfile", title: "Employee Profile", icon: "mdi-information-box" },
  { name: "EmploymentDetails", title: "Employment Details", icon: "mdi-briefcase-account" },
  { name: "PersonalDetails", title: "Personal Details", icon: "mdi-account" },
  { name: "TravelDetails", title: "Travel Details", icon: "mdi-plane-train" },
  { name: "Documents", title: "Documents", icon: "mdi-folder-account" },
  { name: "StaffReports", title: "Staff Reports", icon: "mdi-account-supervisor" },
  { name: "SystemRoles", title: "System Roles", icon: "mdi-account-key" },
])

const userID = ref(props.userID)

var userDetails = ref({})
const staffDetailsForm = ref(null)
var userMedical = ref({})
var userBankAccount = ref({})
var userTax = ref({})
var userTravel = ref({})
var userCompensation = ref({})

var formattedAnnualRemuneration = ref(0)
var canSeeRemuneration = ref(false)
var userRoles = ref({})
var userRolesHumanCapital = ref({})
var userRolesPerformance = ref({})
var userRolesCompensation = ref({})
const userCompanyAdmin = ref({})

var userNextOfKin = ref([])
const userNextOfKinHeaders = [
  { title: "Name", key: "NextOfKinName", value: "NextOfKinName"},
  { title: "Relationship", key: "NextOfKinRelationship", value: "NextOfKinRelationship" },
  { title: "Telephone", key: "NextOfKinTelephone", value: "NextOfKinTelephone" },
  { title: "Actions", key: "actions", width: "90px", sortable: false },
]
var selectedStaffReport = ref(null)
var userStaffReports = ref([])
var userStaffReportsDirect = ref([])
var userStaffReportsIndirect = ref([])
var userStaffReportsPast = ref([])
const userStaffReportsHeaders = [
  { title: "Name", key: "Name", value: "Name"},
  { title: "Email Address", key: "EmailAddress", value: "EmailAddress" },
  { title: "Company Code", key: "CompanyCode", value: "CompanyCode" },
  { title: "Manager Name", key: "ManagerName", value: "ManagerName" },
  { title: "Actions", key: "actions", width: "90px", sortable: false },
]

var userManagementReports = ref([])
const userManagementReportsHeaders = [
  { title: "Name", key: "Name", value: "Name", sortable: false },
  { title: "Email Address", key: "EmailAddress", value: "EmailAddress", sortable: false },
  { title: "Company Code", key: "CompanyCode", value: "CompanyCode", sortable: false },
  { title: "Manager Name", key: "ManagerName", value: "ManagerName", sortable: false },
  { title: "Actions", key: "actions", width: "90px", sortable: false },
]

var userCoworkers = ref([])

var lookups = ref({
  AllCountries: [],
  Companies: [],
  Countries: [],
  Departments: [],
  Silos: [],
  Offices: [],
  EmployeeTypes: [],
  Genders: [],
  MaritalStatuses: [],
  BankAccountTypes: [],
  CompensationTypes: [],
  Currencies: [],
  Managers: [],
})

var yearsOfService = ref(-1)

var inEditMode = ref(false)
var isEditUserValid = ref(false)
var isLoading = ref(true)

//validation Rules
const rulesIsRequired = [v => !!v || "Value is required"]
const rulesIsEmailAddress = [ v => (!v || /^.+@.+\..+$/.test(v)) || "Invalid Email Address (user@domain.com)" ]
const rulesIsPhoneNumber = [v => (!v || /^\+\d{10,13}$/.test(v)) || "Invalid Phone Number (+27112148300)" ]

var dialogEditNextOfKin = ref(false)
var editNextOfKinIndex = ref (-1)
var editNextOfKin = ref ({
  UsersNextOfKinID: null,
  UserID: null,
  NextOfKinName: "",
  NextOfKinRelationship: "",
  NextOfKinTelephone: ""
})

var dialogDeleteNextOfKin = ref(false)

var dialogExitUser = ref(false)
var exitUser = ref({})

var dialogReenableUser = ref(false)
var reenableUser = ref({})

//watch
watch (selectedTab, async (newValue, oldValue) => {
  if (newValue == "StaffReports") {
    await getUserStaffReports()
    await getUserManagementReports()
    await getUserCoworkers()
  }
  else if (newValue == "TravelDetails") {
    await getUserTravel()
  }
  else if (newValue == "SystemRoles") {
    await getUserRoles()
    await getUserCompanyAdmin()
  }
  validateStaffDetailsForm()
})

function updateResultMessage(message) {
  props.updateMessage(message)
}

function getCompanyAdminColour(item) {
  if (item.IsActive === false) {
    return "error"
  }
  else {
    return "primary"
  }
}

function validateStaffDetailsForm() {
  if (staffDetailsForm.value) {
    staffDetailsForm.value.validate()
  }
}

function getTabTitle(tabName) {
  const tab = tabs.value.find((t) => t.name === tabName);
  return tab ? tab.title : null;
}

function getTabIcon(tabName) {
  const tab = tabs.value.find((t) => t.name === tabName);
  return tab ? tab.icon : null;
}

async function getManagerLookup(managerID) {
  if(managerID && managerID != -1) {
    lookups.value["Managers"] = [{
      UserID: userDetails.value.ManagerID,
      Name: userDetails.value.ManagerName,
      EmailAddress: userDetails.value.ManagerEmailAddress,
      CompanyID: userDetails.value.ManagerCompanyID,
      CompanyCode: userDetails.value.ManagerCompanyCode
    }]
  } else {
    let uri = '/Users'

    await utils.httpRequest(uri, utils.REQUEST.GET, (data) => {
      if (managerID == -1) {
        lookups.value["Managers"] = data.filter(function(item) {
          if (!item.hasOwnProperty('IsActive')) return true
          if (item.IsActive === true) return true
          return false
      }).sort(function(a, b) {
        var x = a["Name"]
        var y = b["Name"]
        return ((x < y) ? -1 : ((x > y) ? 1 : 0))
      })
      } else {
        lookups.value["Managers"] = [data]
      }
    })
  }
  console.log(lookups.value["Managers"])
}

async function getAllCountriesLookup() {
  let uri = '/Static/Countries'
  await utils.httpRequest(uri, utils.REQUEST.GET, (data) => {
    lookups.value.AllCountries = data.sort(function(a, b) {
    var x = a["CountryName"]
    var y = b["CountryName"]
    return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  })
}

async function getLookup(variableName, lookupID) {
  let uri = '/Static/' + variableName
  if (lookupID) {
    if(lookupID != -1) {
      uri = uri + '/' + lookupID
    }
    await utils.httpRequest(uri, utils.REQUEST.GET, (data) => {
      if (lookupID == -1) {
        lookups.value[variableName] = data.filter(function(item) {
          if (!item.hasOwnProperty('IsActive')) return true
          if (item.IsActive === true) return true
          return false
      })
      } else {
        lookups.value[variableName] = [data]
      }
    })
    let nameKey
    for (let key in lookups.value[variableName][0]) {
      if (key.endsWith("Name")) {
        nameKey = key
        break
      }
    }
    lookups.value[variableName] = lookups.value[variableName].sort(function(a, b) {
    var x = a[nameKey]
    var y = b[nameKey]
    return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  }
}

async function getLookups() {
  isLoading.value = true
  await getLookup("Companies", -1)
  await getLookup("Countries", -1)
  await getLookup("Departments", -1)
  await getLookup("Silos", -1)
  await getLookup("Offices", -1)
  await getLookup("Genders", -1)
  await getLookup("MaritalStatuses", -1)
  await getLookup("EmployeeTypes", -1)
  await getLookup("BankAccountTypes", -1)
  await getLookup("CompensationTypes", -1)
  await getLookup("Currencies", -1)
  await getLookup("MealTypes", -1)
  await getLookup("SeatingTypes", -1)
  await getManagerLookup(-1)
  isLoading.value = false
}

async function getUserMedical() {
  await utils.httpRequest(`/Users/${userID.value}/Medical`, utils.REQUEST.GET, (data) => {
      userMedical.value = data
  })
}

async function getUserNextOfKin() {
  await utils.httpRequest(`/Users/${userID.value}/NextOfKin`, utils.REQUEST.GET, (data) => {
      userNextOfKin.value = data
  })
}

async function getUserBankAccount() {
  await utils.httpRequest(`/Users/${userID.value}/BankAccount`, utils.REQUEST.GET, (data) => {
      userBankAccount.value = data
      getLookup("BankAccountTypes", data.BankAccountTypeID)
  })
}

async function getUserTax() {
  await utils.httpRequest(`/Users/${userID.value}/Tax`, utils.REQUEST.GET, (data) => {
      userTax.value = data
  })
}

async function getUserTravel() {
  await utils.httpRequest(`/Users/${userID.value}/Travel`, utils.REQUEST.GET, (data) => {
      userTravel.value = data
      getLookup("SeatingTypes", data.SeatingTypeID)
      getLookup("MealTypes", data.MealTypeID)
  })
  await getAllCountriesLookup()
}

async function getUserCompensation() {
  await utils.httpRequest(`/Users/${userID.value}/Compensation`, utils.REQUEST.GET, (data) => {
      userCompensation.value = data
      formatAnnualRemuneration()
      getLookup("CompensationTypes", data.CompensationTypeID)
      getLookup("Currencies", data.CurrencyID)
  })
}

async function getUserStaffReports() {
  await utils.httpRequest(`/Users/${userID.value}/StaffReports`, utils.REQUEST.GET, (data) => {
      userStaffReports.value = data
      userStaffReportsDirect.value = userStaffReports.value.filter(item => (item.IsActive == true) && (item.ManagerID == userID.value))
      userStaffReportsIndirect.value = userStaffReports.value.filter(item => (item.IsActive == true) && (item.ManagerID != userID.value))
      userStaffReportsPast.value = userStaffReports.value.filter(item => (item.IsActive == false))
    })
}

async function getUserManagementReports() {
  await utils.httpRequest(`/Users/${userID.value}/ManagementReports`, utils.REQUEST.GET, (data) => {
      userManagementReports.value = data.sort((a, b) => a.Steps - b.Steps)
    })
}

async function getUserCoworkers() {
  await utils.httpRequest(`/Users/${userID.value}/Coworkers`, utils.REQUEST.GET, (data) => {
      userCoworkers.value = data.reverse()
    })
}

async function getUserCompanyAdmin() {
  await utils.httpRequest(`/Auth/${userID.value}/CompanyAdmins`, utils.REQUEST.GET, (data) => {
    userCompanyAdmin.value = data
  })
}

async function getUserRoles() {
  await utils.httpRequest(`/Auth/${userID.value}/Roles`, utils.REQUEST.GET, (data) => {
      userRoles.value = data
      userRolesHumanCapital.value = userRoles.value.filter(item => (item.Section == "HumanCapital"))
      userRolesPerformance.value = userRoles.value.filter(item => (item.Section == "Performance"))
      userRolesCompensation.value = userRoles.value.filter(item => (item.Section == "Compensation"))
  })
}

async function getUserDetails() {
  if (userID.value) {
    await utils.httpRequest(`/Users/${userID.value}/Details`, utils.REQUEST.GET, (data) => {
      userDetails.value = data
      //Calculate Fields
      yearsOfService.value = data.StartDate ? (dateAdapter.getDiff((data.EndDate ? new Date(data.EndDate) : dateAdapter.date()), new Date(data.StartDate), "months")/12).toFixed(2) : 0
      //Get Lookups
      getLookup("Companies", data.CompanyID)
      getLookup("Countries", -1)
      getLookup("Departments", data.DepartmentID)
      getLookup("Silos", data.SiloID)
      getLookup("Offices", data.OfficeID)
      getLookup("Genders", data.GenderID)
      getLookup("MaritalStatuses", data.MaritalStatusID)
      getLookup("EmployeeTypes", data.EmployeeTypeID)
      getManagerLookup(data.ManagerID)
    })

    //Get Additional Information
    await getUserMedical()
    await getUserNextOfKin()
    await getUserBankAccount()
    await getUserCompensation()
    await getUserTax()

    isLoading.value = false
  }
}

async function saveUserTravel() {
  let payload = userTravel.value

  if (Object.keys(payload).length > 0) {
    try {
      await utils.httpRequest(`/Users/${userID.value}/Travel`, utils.REQUEST.POST, (data) => {
      if (data.Error) {
          throw new Error(data.Error.Message)
        }
        userTravel.value = data
      },
      payload)
      return true
    } catch (e) {
        updateResultMessage("error|Save error (saveUserTravel)|" + e.message)
        return false
    }
  }
  else {
    return true
  }
}

async function saveUserMedical() {
  let payload = userMedical.value

  if (Object.keys(payload).length > 0) {
    try {
      await utils.httpRequest(`/Users/${userID.value}/Medical`, utils.REQUEST.POST, (data) => {
      if (data.Error) {
          throw new Error(data.Error.Message)
        }
        userMedical.value = data
      },
      payload)
      return true
    } catch (e) {
        updateResultMessage("error|Save error (saveUserMedical)|" + e.message)
        return false
    }
  }
  else {
    return true
  }
}

async function saveUserCompensation() {
  let payload = userCompensation.value

  if (Object.keys(payload).length > 0) {
    try {
      await utils.httpRequest(`/Users/${userID.value}/Compensation`, utils.REQUEST.POST, (data) => {
      if (data.Error) {
          throw new Error(data.Error.Message)
      }
        userCompensation.value = data
      },
      payload)
      return true
    } catch (e) {
        updateResultMessage("error|Save error (saveUserCompensation)|" + e.message)
        return false
    }
  }
  else {
    return true
  }
}

async function saveUserNextOfKin() {
  let payload = userNextOfKin.value
  try {
    await utils.httpRequest(`/Users/${userID.value}/NextOfKin`, utils.REQUEST.POST, (data) => {
    if (data.Error) {
        throw new Error(data.Error.Message)
      }
      userNextOfKin.value = data
    },
    payload)
    return true
  } catch (e) {
      updateResultMessage("error|Save error (saveUserNextOfKin)|" + e.message)
      return false
  }
}

async function saveUserDetails() {
  let payload = userDetails.value
  try {
    await utils.httpRequest(`/Users/${userID.value}/Details`, utils.REQUEST.POST, (data) => {
    if (data.Error) {
        throw new Error(data.Error.Message)
      }
      userDetails.value = data
    },
    payload)
    return true
  } catch (e) {
      updateResultMessage("error|Save error (saveUserDetails)|" + e.message)
      return false
  }
}

async function setUserReenable() {
  let payload = {
    IsActive: true,
    StartDate: reenableUser.value.ProposedStartDate,
    ManagerID: reenableUser.value.ManagerID
  }

  try {
    await utils.httpRequest(`/Users/${userID.value}/Reenable`, utils.REQUEST.POST, (data) => {
      if (data.Error) {
        throw new Error(data.Error.Message)
      }
      updateResultMessage("success|User Re-Activate|User Re-activation Completed")
    },
    payload)
  } catch (e) {
      updateResultMessage("error|User Re-Activate|" + e.message)
  }
}

async function setUserDisable() {
  let payload = {
    IsActive: false,
    EndDate: exitUser.value.ProposedEndDate
  }

  try {
    await utils.httpRequest(`/Users/${userID.value}/Disable`, utils.REQUEST.POST, (data) => {
      if (data.Error) {
          throw new Error(data.Error.Message)
        }
      updateResultMessage("success|User Exit|User Exit Completed")
    },
    payload)
  } catch (e) {
      updateResultMessage("error|User Exit|" + e.message)
  }
}

async function setUserExit() {
  try {
    let payload = exitUser.value
    payload["RequestedByUserID"] = localStorage.getItem("user_id")

    await utils.httpRequest(`/Workflows/UserExit`, utils.REQUEST.POST, (data) => {
        if (data.Error) {
          throw new Error(data.Error.Message)
        }
      },
      payload
    )
    updateResultMessage("success|User Exit|User Exit worfklows created")
    getUserDetails()
  } catch (e) {
    updateResultMessage("error|User Exit|" + e.message)
  }
}

function saveEditNextOfKin() {
  if (editNextOfKinIndex.value > -1) {
    Object.assign(userNextOfKin.value[editNextOfKinIndex.value], editNextOfKin.value)
  }
  else {
    userNextOfKin.value.push(editNextOfKin.value)
  }

  dialogEditNextOfKin.value = false
}

function openDialogEditNextOfKin(item) {
  editNextOfKinIndex.value = userNextOfKin.value.indexOf(item)
  editNextOfKin.value = Object.assign({}, item)

  dialogEditNextOfKin.value = true
}

function closeDialogEditNextOfKin() {
  dialogEditNextOfKin.value = false
}

function deleteNextOfKinConfirm() {
  userNextOfKin.value.splice(editNextOfKinIndex.value, 1)
  closeDialogDeleteNextOfKin()
}

function openDialogDeleteNextOfKin(item) {
  editNextOfKinIndex.value = userNextOfKin.value.indexOf(item)
  editNextOfKin.value = Object.assign({}, item)
  dialogDeleteNextOfKin.value = true
}

function closeDialogDeleteNextOfKin() {
  dialogDeleteNextOfKin.value = false
}

async function saveExitUser() {
  if (exitUser.value.CreateExitWorkflows) {
    await setUserExit()
  }
  else {
    await setUserDisable()
  }
  dialogExitUser.value = false
  await getUserDetails()
}

async function saveReenableUser() {
  await setUserReenable()
  dialogReenableUser.value = false
  userDetails.value = {}
  await getUserDetails()
}

async function openDialogExitUser() {
  await getManagerLookup(-1)
  exitUser.value.UserID = userDetails.value.UserID
  exitUser.value.ProposedEndDate = today
  exitUser.value.CreateExitWorkflows = true
  exitUser.value.ManagerID = userDetails.value.ManagerID

  dialogExitUser.value = true
}

async function openDialogReenableUser() {
  await getManagerLookup(-1)
  reenableUser.value.UserID = userDetails.value.UserID
  reenableUser.value.ProposedStartDate = today
  reenableUser.value.ManagerID = userDetails.value.ManagerID

  dialogReenableUser.value = true
}

function closeDialogExitUser() {
  dialogExitUser.value = false
}

function closeDialogReenableUser() {
  dialogReenableUser.value = false
}

async function editUser() {
  await getLookups()
  await validateStaffDetailsForm()

  inEditMode.value = true
}

async function cancelEditUser() {
  inEditMode.value = false
  canSeeRemuneration.value = false
  updateResultMessage("warning|Cancelled|Save Cancelled")
  await getUserDetails()
}

async function saveUser() {
  let rtn = true
  rtn = ( rtn ? (await saveUserDetails()) && rtn : false )
  rtn = ( rtn ? (await saveUserMedical()) && rtn : false )
  rtn = ( rtn ? (await saveUserNextOfKin()) && rtn : false )
  rtn = ( rtn ? (await saveUserCompensation()) && rtn : false )
  rtn = ( rtn ? (await saveUserTravel()) && rtn : false )

  if (rtn) {
    inEditMode.value = false
    canSeeRemuneration.value = false
    updateResultMessage("success|Done|Successfully Saved")
  }

  invaliddatapanel.value.refreshData()
}

function getCountryCode(countryID) {
  const country = Array.isArray(lookups.value["Countries"]) ? lookups.value["Countries"].find(c => c.CountryID === countryID) : lookups.value["Countries"]
  return country ? country.CountryCode : countryID
}

function routeToUserProfile(userID) {
  const routeData = router.resolve({
      name: "staff-profile",
      params: { id: userID },
    })
  window.open(routeData.href, "_blank")
}

function sendMail(emailAddress) {
  window.open(`mailto:${emailAddress}`)
  updateResultMessage("success|Sending Mail|" + emailAddress)
}

function formatAnnualRemuneration() {
  if (userCompensation.value.AnnualRemuneration) {
    const locale = getLocaleFromCurrency(userCompensation.value.CurrencyCode)
    formattedAnnualRemuneration.value = Number(userCompensation.value.AnnualRemuneration || null).toLocaleString(locale, {
      style: 'currency',
      currency: (userCompensation.value.CurrencyCode || 'USD'),
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
  })
  }
  else {
    formattedAnnualRemuneration.value = null
  }
}

function getLocaleFromCurrency(key) {
  const rtn = utils.LOCALENAMEFROMCURRENCY[key] || undefined
  return rtn
}

const fieldEditRules = [
{ FieldName: "UserName", admin: false, it: true, user: false, manager: false, company: false },
{ FieldName: "FirstNames", admin: true, it: false, user: false, manager: false, company: true },
{ FieldName: "PreferredName", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "LastNames", admin: true, it: false, user: false, manager: false, company: true },
{ FieldName: "EmailAddress", admin: true, it: true, user: false, manager: false, company: true },
{ FieldName: "CellPhone", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "JobTitle", admin: true, it: false, user: false, manager: true, company: true },
{ FieldName: "ManagerID", admin: true, it: false, user: false, manager: false, company: true },
{ FieldName: "StartDate", admin: true, it: false, user: false, manager: false, company: true },
{ FieldName: "EmployeeNumber", admin: true, it: false, user: false, manager: false, company: true },
{ FieldName: "EmployeeTypeID", admin: true, it: false, user: false, manager: false, company: true },
{ FieldName: "CountryID", admin: true, it: false, user: false, manager: true, company: true },
{ FieldName: "CompanyID", admin: true, it: false, user: false, manager: true, company: true },
{ FieldName: "SiloID", admin: true, it: false, user: false, manager: true, company: true },
{ FieldName: "DepartmentID", admin: true, it: false, user: false, manager: true, company: true },
{ FieldName: "OfficeID", admin: true, it: false, user: false, manager: true, company: true },
{ FieldName: "CompensationTypeID", admin: true, it: false, user: false, manager: true, company: true },
{ FieldName: "CurrencyID", admin: true, it: false, user: false, manager: false, company: true },
{ FieldName: "AnnualRemuneration", admin: true, it: false, user: false, manager: false, company: true },
{ FieldName: "DateOfBirth", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "IDNumber", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "GenderID", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "MaritalStatusID", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "Address1", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "Address2", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "IsCompanyMedicalAid", admin: true, it: false, user: false, manager: false, company: true },
{ FieldName: "MedicalAidName", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "MedicalAidPlan", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "MedicalAidNumber", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "DoctorName", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "DoctorTelephone", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "NumberOfDependents", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "NextOfKin", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "IsTravelSystemUser", admin: true, it: true, user: false, manager: true, company: true },
{ FieldName: "SeatingTypeID", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "MealTypeID", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "Passport1Name", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "Passport1Number", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "Passport1CountryID", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "Passport1Expiry", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "Passport2Name", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "Passport2Number", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "Passport2CountryID", admin: true, it: false, user: true, manager: true, company: true },
{ FieldName: "Passport2Expiry", admin: true, it: false, user: true, manager: true, company: true },
]

function canEdit(fieldName) {
  let matchingRule = fieldEditRules.find(item => item.FieldName == fieldName)

  if (!matchingRule) {
    console.log("CANNOT FIND ", fieldName)
  }

  let allowEditUser = (matchingRule.user && isAuthorizedUser.value)
  let allowEditAdmin = (matchingRule.admin && isAuthorizedAdmin.value)
  let allowEditIT = (matchingRule.it && isAuthorizedIT.value)
  let allowEditManager = (matchingRule.manager && isAuthorizedUserManager.value)
  let allowEditCompanyAdmin = (matchingRule.company && isAuthorizedCompanyAdmin.value)
  let allowEdit = (allowEditUser || allowEditAdmin || allowEditIT || allowEditManager || allowEditCompanyAdmin)

//  if (fieldName == "StartDate") {
//    console.log(fieldName, ": Edit User? => ", allowEditUser)
//    console.log(fieldName, ": Edit Admin? => ", allowEditAdmin)
//    console.log(fieldName, ": Edit IT? => ", allowEditIT)
//    console.log(fieldName, ": Edit Manager? => ", allowEditIT)
//    console.log(fieldName, ": Allow Edit? => ", allowEdit)
//    console.log(fieldName, ": Final Result? => ", (inEditMode.value && allowEdit) || false)
//  }

  let rtn = (inEditMode.value && allowEdit) || false
  return rtn
}

onMounted(async () => {
  let loggedInUser = localStorage.getItem("user_id")
  if (loggedInUser) {
    await getUserDetails()
    validateStaffDetailsForm()

    if (loggedInUser != userID.value) {
      selectedTab.value = "EmployeeProfile"
    }

    isAuthorizedAdmin.value = (await authService.isHumanCapitalAdmin())
    isAuthorizedUser.value = (loggedInUser == userID.value);
    isAuthorizedUserManager.value = (userManagementReports.value.filter(item => (item.ManagerID == loggedInUser)).length > 0)
    isAuthorizedIT.value = (await authService.isHumanCapitalIT())
    isAuthorizedCompanyAdmin.value = (await authService.isAdminForCompany(userDetails.value.CompanyID))
  }
  else {
    //Cannot find profile, stop loading
    isLoading = false
  }
})
</script>
