<template>
  <div class="ml-3">
    <v-row class="mt-1">
      <v-col cols="2">
        <v-card
          class="d-flex flex-column"
          rounded="xl"
          color="primary"
          elevation="4">
          <v-card-title
            class="ml-4 mt-4">
            <v-row>
              <v-col class="text-h6">Sections</v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-divider color="white" class="mb-4"></v-divider>
            <v-tabs
              class="ml-n2 mr-n2 mt-auto"
              density="comfortable"
              direction="vertical"
              v-model="selectedLookupTab">
              <v-tab
                v-for="tab in sections"
                :value="tab.name"
                :to="tab.name"
                variant="text"
                slim
              >
                <v-icon left size="large">{{ tab.icon }}</v-icon>
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="10">
        <v-container
          fluid
          class="mt-n4"
        >
          <v-row>
            <v-col>
              <v-card
                class="mt-0 ml-2 mr-2 d-flex flex-column"
                rounded="xl"
                style="overflow-y: auto"
                elevation="4">
                <v-card-title class="bg-secondary">
                  <v-col>
                    <v-icon :icon="getSectionIcon(selectedLookupTab)" size="large"></v-icon>
                    {{ getSectionTitle(selectedLookupTab) }}
                  </v-col>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="mr-n6">
              <v-window
                continuous
                direction="horizontal"
                v-model="selectedLookupTab">
                  <v-window-item value="HumanCapital">
                    <v-row>
                      <v-col cols="2">
                        <v-card
                          class="d-flex flex-column ml-1 mt-2"
                          rounded="xl"
                          color="secondary"
                          elevation="4">
                          <v-card-text>
                            <v-tabs
                              class="mt-n1 ml-2 mr-4"
                              density="comfortable"
                              direction="vertical"
                              v-model="selectedRoleHumanCapital">
                              <v-tab
                                v-for="tab in rolesHumanCapital"
                                :value="tab.RoleID"
                                variant="text"
                                slim
                              >
                              {{ tab.RoleName }}
                              </v-tab>
                            </v-tabs>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-window v-model="selectedRoleHumanCapital" class="pt-2 pr-4">
                          <v-window-item
                            v-for="tab in rolesHumanCapital"
                            :value="tab.RoleID">
                            <roledetail :updateMessage="updateResultMessage" :roleID="tab.RoleID"></roledetail>
                          </v-window-item>
                        </v-window>
                      </v-col>
                    </v-row>
                  </v-window-item>
                
                  <v-window-item value="Performance">
                    <v-row>
                      <v-col cols="2">
                        <v-card
                          class="d-flex flex-column ml-2 mt-2"
                          rounded="xl"
                          color="secondary"
                          elevation="4">
                          <v-card-text>
                            <v-tabs
                              class="mt-n1 ml-2 mr-4"
                              density="comfortable"
                              direction="vertical"
                              v-model="selectedRolePerformance">
                              <v-tab
                                v-for="tab in rolesPerformance"
                                :value="tab.RoleID"
                                variant="text"
                                slim
                              >
                              {{ tab.RoleName }}
                              </v-tab>
                            </v-tabs>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-window v-model="selectedRolePerformance" class="pt-2 pr-4">
                          <v-window-item
                            v-for="tab in rolesPerformance"
                            :value="tab.RoleID">
                            <roledetail :updateMessage="updateResultMessage" :roleID="tab.RoleID"></roledetail>
                          </v-window-item>
                        </v-window>
                      </v-col>
                    </v-row>
                  </v-window-item>
                
                  <v-window-item value="Compensation">
                    <v-row>
                      <v-col cols="2">
                        <v-card
                          class="d-flex flex-column ml-1 mt-2"
                          rounded="xl"
                          color="secondary"
                          elevation="4">
                          <v-card-text>
                            <v-tabs
                              class="mt-n1 ml-2 mr-4"
                              density="comfortable"
                              direction="vertical"
                              v-model="selectedRoleCompensation">
                              <v-tab
                                v-for="tab in rolesCompensation"
                                :value="tab.RoleID"
                                variant="text"
                                slim
                              >
                              {{ tab.RoleName }}
                              </v-tab>
                            </v-tabs>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-window v-model="selectedRoleCompensation" class="pt-2 pr-4">
                          <v-window-item
                            v-for="tab in rolesCompensation"
                            :value="tab.RoleID">
                            <roledetail :updateMessage="updateResultMessage" :roleID="tab.RoleID"></roledetail>
                          </v-window-item>
                        </v-window>
                      </v-col>
                    </v-row>
                  </v-window-item>
                </v-window>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import authService from '@/services/authService'
import utils from '@/services/utils'

const route = useRoute()
const router = useRouter()

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

var selectedLookupTab = ref("")

const sections = ref([
  { name: "HumanCapital", title: "Human Capital", icon: "mdi-human-queue" },
  { name: "Performance", title: "Performance", icon: "mdi-chart-bar" },
  { name: "Compensation", title: "Compensation", icon: "mdi-cash" },
])
var roles = ref([])
var rolesHumanCapital = ref([])
var rolesPerformance = ref([])
var rolesCompensation = ref([])

var selectedRoleHumanCapital = ref(0)
var selectedRolePerformance = ref(0)
var selectedRoleCompensation = ref(0)

async function getRoles() {
  await utils.httpRequest(`/Roles`, utils.REQUEST.GET, (data) => {
      roles.value = data
      rolesHumanCapital.value = roles.value.filter(item => (item.Section == "HumanCapital"))
      rolesPerformance.value = roles.value.filter(item => (item.Section == "Performance"))
      rolesCompensation.value = roles.value.filter(item => (item.Section == "Compensation"))
  })
}

function getSectionTitle(tabName) {
  const tab = sections.value.find((t) => t.name === tabName);
  return tab ? tab.title : null;
}

function getSectionIcon(tabName) {
  const tab = sections.value.find((t) => t.name === tabName);
  return tab ? tab.icon : null;
}

onMounted(async () => {
  let isAuthorized = await authService.isHumanCapitalAdmin()

  if (!isAuthorized) {
    updateResultMessage("error|Not Authorized|You are not authorized to view Roles")
    router.push({
      name: "not-authenticated"
    })
  }

  selectedLookupTab = ref(route.params.id || "HumanCapital")
  if (!route.params.id) {
    router.push("/roles/HumanCapital")
  }

  await getRoles()

  emit("updateBreadcrumb", ["Roles", route.params.id])
  emit("updateHeader", "Roles - " + route.params.id)
})

</script>