<template>
  <div class="ml-3 mr-6">
    <v-expansion-panels
      rounded="xl">
      <v-expansion-panel
        static
        color="secondary">
        <v-expansion-panel-title>
          <v-row>
            <v-col class="d-flex justify-end">
              Filters and Options
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text
          class="mb-n4">
          <v-row>
            <v-col></v-col>
            <v-col class="d-flex justify-end">
              <xlsxexportbutton class="mr-2" :rawJSON="filteredItems" :updateMessage="updateResultMessage"></xlsxexportbutton>
              <v-btn
                variant="tonal"
                color="primary"
                icon=""
                @click="addNew()"
              >
                <v-icon icon="mdi-plus-thick" />
                <v-tooltip
                  activator="parent"
                  location="top"
                >Add New
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <div class="mb-6 pb-10"></div>
            </v-col>
            <v-col></v-col>
            <v-col cols="1"><div class="d-flex justify-end">
              <v-switch
                v-model="selectedIsActive"
                variant="solo"
                label="Is Active?"
                density="compact"
                color="primary"
              >
              </v-switch>
            </div></v-col>
            <v-col justify="end">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                density="compact"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      density="compact"
      v-model:search="search"
      :headers="globalNotificationListHeaders"
      :items="filteredItems"
      item-value="GlobalNotificationID"
      items-per-page="100"
      >
      <template v-slot:item.IsActive="{ item }">
        <v-icon
          v-if="item.IsActive"
          size="sm"
          icon="mdi-check"
          color="success"
        ></v-icon>
        <v-icon
          v-if="! item.IsActive"
          size="sm"
          icon="mdi-cancel"
          color="error"
        ></v-icon>
      </template>
      <template v-slot:item.NotificationType="{ item }">
        <v-icon
        v-if="item.NotificationType == 'info'"
          size="sm"
          icon="mdi-information"
          :color="item.NotificationType"
        ></v-icon>
        <v-icon
        v-if="item.NotificationType == 'warning'"
          size="sm"
          icon="mdi-alert"
          :color="item.NotificationType"
        ></v-icon>
        <v-icon
        v-if="item.NotificationType == 'error'"
          size="sm"
          icon="mdi-close-circle"
          :color="item.NotificationType"
        ></v-icon>
        {{ getNotificationTypeTitle(item.NotificationType) }}
      </template>
      <template v-slot:item.UserID="{ item }">
        <v-btn
          v-if="item.EmailAddress"
          variant="text"
          size="sm"
          color="secondary"
          icon=""
          @click="sendMail(item.EmailAddress)"
          ><v-icon icon="mdi-email" />
            <v-tooltip
              activator="parent"
              location="top"
            >Send mail to {{ item.EmailAddress }}
            </v-tooltip>
          </v-btn>
        {{ item.Name }}
      </template>
      <template v-slot:item.StartDate="{ item }">
        {{ formattedDate(item.StartDate) }}
      </template>
      <template v-slot:item.EndDate="{ item }">
        {{ formattedDate(item.EndDate) }}
      </template>
      <template v-slot:item.LastUpdated="{ item }">
        {{ formattedDate(item.LastUpdated) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div>
          <v-btn
            color="primary"
            icon=""
            variant="plain"
            density="compact"
            @click="showEdit(item.GlobalNotificationID)"
          ><v-icon size="sm" icon="mdi-eye" />
            <v-tooltip
              activator="parent"
              location="top"
            >View
            </v-tooltip>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="showDialogEditItem" width="750px">
      <v-form ref="editForm" v-model="isEditValid">
        <v-card>
          <v-card-title>Edit Global Notification</v-card-title>
          <v-card-text>
            <v-row><v-col></v-col></v-row>
            <v-row>
              <v-col class="pa-0 ma-1" cols="2"><v-text-field
                density="compact"
                persistent-placeholder
                variant="plain"
                readonly
                type="number"
                label="ID"
                v-model="editItem.GlobalNotificationID"
              /></v-col>
              <v-col>
                <div class="d-flex justify-end mt-n6"><v-switch
                  density="compact"
                  color="primary"
                  label="Is Active?"
                  v-model="editItem.IsActive"
                ></v-switch></div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0 ma-1"><v-autocomplete
                class=""
                density="compact"
                persistent-placeholder
                variant="underlined"
                label="Notification Type"
                :rules="rulesIsRequired"
                v-model="editItem.NotificationType"
                :items="notificationTypes"
                :item-title="item => item.title"
                :item-value="item => item.value"
              /></v-col>
              <v-col class="pa-0 ma-1"><datepicker
                density="compact"
                color="secondary"
                variant="underlined"
                label="Start Date"
                :rules="rulesIsRequired"
                v-model="editItem.StartDate"
              ></datepicker></v-col>
              <v-col class="pa-0 ma-1"><datepicker
                density="compact"
                color="secondary"
                variant="underlined"
                label="End Date"
                :rules="rulesIsRequired"
                v-model="editItem.EndDate"
              ></datepicker></v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0 ma-1"><v-text-field
                density="compact"
                persistent-placeholder
                variant="underlined"
                label="Header"
                :rules="rulesIsRequired"
                v-model="editItem.Header"
              /></v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0 ma-1"><v-textarea
                 density="compact"
                 persistent-placeholder
                 variant="underlined"
                 no-resize
                 rows="4"
                 label="Description"
                 :rules="rulesIsRequired"
                 v-model="editItem.Description"
               /></v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :disabled="!isEditValid"
              @click="saveEditItem(editItem.GlobalNotificationID)"
            >Save</v-btn>
            <v-btn
              color="error"
              @click="cancelEditItem()"
            >Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import authService from '@/services/authService'
import utils from '@/services/utils'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])

const router = useRouter()

const notificationTypes = [
{ title: "Info", key: "info", value: "info"},
{ title: "Warning", key: "warning", value: "warning"},
{ title: "Error", key: "error", value: "error"},
]

const globalNotificationListHeaders = [
  { title: "", key: "IsActive", value: "IsActive", width: "80px" },
  { title: "Notification Type", key: "NotificationType", value: "NotificationType"},
  { title: "Header", key: "Header", value: "Header" },
  { title: "User", key: "UserID", value: "UserID" },
  { title: "Start Date", key: "StartDate", value: "StartDate" },
  { title: "End Date", key: "EndDate", value: "EndDate" },
  { title: "Last Updated", key: "LastUpdated", value: "LastUpdated" },
  { title: "Actions", key: "actions", width: "90px", sortable: false },
]
var globalNotificationList = ref([])

var search = ref(null)
var filteredItems = ref([])
var selectedIsActive = ref(true)

const editForm = ref(null)
var showDialogEditItem = ref(false)
var usersList = ref([])
var editItem = ref({})
var isEditValid = ref(false)

const rulesIsRequired = [v => !!v || "Value is required"]

function getNotificationTypeTitle(notificationType) {
  return notificationTypes.find(c => c.value === notificationType).title
}

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

watch(selectedIsActive, (newValue, oldValue) => {
  resetFilter()
})

function sendMail(emailAddress) {
  window.open(`mailto:${emailAddress}`)
  updateResultMessage("success|Sending Mail|" + emailAddress)
}

function formattedDate(selectedDate) {
  return utils.formatDatePeriod(selectedDate, true)
}
function resetFilter() {
  filteredItems.value = globalNotificationList.value.filter(item => {
    let isActiveMatch = selectedIsActive.value ? item.IsActive === selectedIsActive.value : true
    return isActiveMatch
  })
}

async function getGlobalNotifications() {
  await utils.httpRequest(`/GlobalNotifications/`, utils.REQUEST.GET, (data) => {
      globalNotificationList.value = data
  })
  resetFilter()
}

async function getOneData(dataID) {
  await utils.httpRequest(`/GlobalNotifications/${dataID}`, utils.REQUEST.GET, (data) => {
    editItem.value = data
  })
}

async function showEdit(dataID) {
  await getOneData(dataID)
  await validateEditForm()
  showDialogEditItem.value = true
}

function validateEditForm() {
  if (editForm.value) {
    editForm.value.validate()
  }
}
function addNew() {
  editItem.value = {}
  editItem.value.IsActive = true
  editItem.value.UserID = localStorage.getItem("user_id")
  editItem.value.GlobalNotifcationID = null
  showDialogEditItem.value = true
  validateEditForm()
}

async function saveEditItem(dataID) {
  let payload = editItem.value
  let uri = "/GlobalNotifications"
  if (dataID) {
    uri = uri + `/${dataID}`
  }
  try {
    await utils.httpRequest(uri, utils.REQUEST.POST, (data) => {
    if (!data.Error) {
          updateResultMessage("success|Saved|Saved Successfully", )
        } else {
          throw new Error(data.Error.Message)
        }
    },
    payload)
    await getGlobalNotifications()
    showDialogEditItem.value = false
  } catch (e) {
      updateResultMessage("error|Save error|" + e.message)
  }
}

function cancelEditItem() {
  showDialogEditItem.value = false
}

onMounted(async () => {
  let isAuthorized = (await authService.isHumanCapitalAdmin()) || (await authService.isHumanCapitalIT())

  if (!isAuthorized) {
    updateResultMessage("error|Not Authorized|You are not authorized to view the Global Notifications")
    router.push({
      name: "not-authenticated"
    })
  }

  emit("updateBreadcrumb", ["Global Notifications"])
  emit("updateHeader", "Global Notifications")

  await getGlobalNotifications()
})
</script>