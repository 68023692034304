<template>
  <div class="ml-3">
    <v-row>
      <v-col cols="1"></v-col>
      <v-col>
        <v-card>
          <v-card-title class="text-h5 text-primary mb-3">
            <div>
            {{ workflow.WorkflowTypeName }}
            </div>
            <div align-content="end" class="text-right mt-n6">
              WORKFLOW-{{ workflow.WorkflowNotificationID }}
            </div>
          </v-card-title>
          <v-card-subtitle class="text-h6 mb-4">Requested by
            <v-btn
              class="mt-n1"
              size="small"
              color="secondary"
              icon=""
              variant="text"
              density="compact"
              @click="sendMail(workflow.RequestedByUserEmailAddress)"
            ><v-icon icon="mdi-email" />
             <v-tooltip
               activator="parent"
               location="top"
             >Send mail to {{ workflow.RequestedByUserEmailAddress }}
             </v-tooltip></v-btn>
             {{ workflow.RequestedByUserName }}
          </v-card-subtitle>
          <v-card-text>
            <div class="mb-12 text-body-1"><v-sheet elevation="2" rounded class="px-4">
              <v-row>
                <v-col class="text-h5 text-center text-primary">{{ workflow.Summary }}</v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-bold">First Name:</v-col>
                <v-col cols="3">{{ workflow.FirstNames }}</v-col>
                <v-col class="font-weight-bold">Preferred Name:</v-col>
                <v-col cols="3">{{ workflow.PreferredName }}</v-col>
                <v-col class="font-weight-bold">Last Name:</v-col>
                <v-col cols="3">{{ workflow.LastNames }}</v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-bold">Start Date:</v-col>
                <v-col cols="4">{{ formattedDate(workflow.StartDate) }}</v-col>
                <v-col class="font-weight-bold"></v-col>
                <v-col cols="4"></v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-bold">Job Title:</v-col>
                <v-col cols="4">{{ workflow.JobTitle }}</v-col>
                <v-col class="font-weight-bold">Manager:</v-col>
                <v-col cols="4">
                  <v-btn
                    size="small"
                    color="secondary"
                    icon=""
                    variant="text"
                    density="compact"
                    @click="sendMail(workflow.ManagerEmailAddress)"
                  ><v-icon icon="mdi-email" />
                   <v-tooltip
                     activator="parent"
                     location="top"
                   >Send mail to {{ workflow.ManagerEmailAddress }}
                   </v-tooltip></v-btn>
                   {{ workflow.ManagerName }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-bold">Country:</v-col>
                <v-col cols="4">{{ workflow.CountryName }}</v-col>
                <v-col class="font-weight-bold">Office:</v-col>
                <v-col cols="4">{{ workflow.OfficeName }}</v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-bold">Company:</v-col>
                <v-col cols="4">{{ workflow.CompanyName }}</v-col>
                <v-col class="font-weight-bold"></v-col>
                <v-col cols="4"></v-col>
              </v-row>
              <v-row>                
                <v-col class="font-weight-bold">Department:</v-col>
                <v-col cols="4">{{ workflow.DepartmentName }}</v-col>
                <v-col class="font-weight-bold">Silo:</v-col>
                <v-col cols="4">{{ workflow.SiloName }}</v-col>
              </v-row>
              <div v-if="workflow.WorkflowPageName !== 'workflow/staff-new-office'">
                <v-row>
                  <v-col class="font-weight-bold mb-n4">Special Instructions (IT):</v-col>
                </v-row>
                <v-row>
                  <v-col v-if="workflow.SpecialRequestsIT">{{ workflow.SpecialRequestsIT }}</v-col>
                  <v-col v-if="!workflow.SpecialRequestsIT" class="font-italic">None</v-col>
                </v-row>
              </div>
              <div v-if="workflow.WorkflowPageName !== 'workflow/staff-new-it'">
                <v-row>
                  <v-col class="font-weight-bold mb-n4">Special Instructions (Office):</v-col>
                </v-row>
                <v-row>
                  <v-col v-if="workflow.SpecialRequestsOffice">{{ workflow.SpecialRequestsOffice }}</v-col>
                  <v-col v-if="!workflow.SpecialRequestsOffice" class="font-italic">None</v-col>
                </v-row>
              </div>
            </v-sheet></div>
            <div v-if="workflow.IsComplete" class="text-body-1 ml-6">
              This workflow has been marked as complete.
            </div>
            <div v-if="!workflow.IsComplete">
              <v-form ref="workflowForm" v-model="isFormValid">
                <div v-if="workflow.WorkflowPageName === 'workflow/staff-new-it'">
                  <v-row>
                    <span class="text-h6 text-primary ml-4">IT Checklist</span>
                  </v-row>
                  <v-row>
                    <v-divider class="mb-6"></v-divider>
                  </v-row>
                  <v-row v-if="workflow.RequiresEmailAddress">
                    <v-col cols="3" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Email Account Assigned?"
                      v-model="requiredFields.EmailAddressAssigned"
                      :rules="rulesIsRequired"
                    ></v-switch></v-col>
                    <v-col><v-text-field
                      density="compact"
                      persistent-placeholder
                      variant="underlined"
                      label="Email Address"
                      v-model="requiredFields.EmailAddress"
                      :rules="rulesIsRequired && rulesIsEmailAddress"
                    /></v-col>
                  <v-row v-if="!workflow.RequiresEmailAddress">
                    <v-col><div class="text-body-1 font-weight-thin mt-3 mb-7 ml-3">No Email Address is Required</div></v-col>
                  </v-row>
                  </v-row>
                  <v-row v-if="workflow.RequiresLaptop">
                    <v-col cols="3" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Workstation Assigned?"
                      v-model="requiredFields.LaptopAssigned"
                      :rules="rulesIsRequired"
                    ></v-switch></v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row v-if="!workflow.RequiresLaptop">
                    <v-col><div><p class="text-body-1 font-weight-thin mt-3 mb-7 ml-3">No Workstation is Required</p></div></v-col>
                  </v-row>
                  <v-row v-if="workflow.RequiresTelephone">
                    <v-col cols="3" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Telephone Extension Assigned?"
                      v-model="requiredFields.TelephoneAssigned"
                      :rules="rulesIsRequired"
                    ></v-switch></v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row v-if="!workflow.RequiresTelephone">
                    <v-col><div><p class="text-body-1 font-weight-thin mt-3 mb-7 ml-3">No Telephone is Required</p></div></v-col>
                  </v-row>
                </div>
                <div v-if="workflow.WorkflowPageName === 'workflow/staff-new-office'">
                  <v-row>
                    <span class="text-h6 text-primary ml-4">Office Checklist</span>
                  </v-row>
                  <v-row>
                    <v-divider class="mb-6"></v-divider>
                  </v-row>
                  <v-row v-if="workflow.RequiresBuildingAccess">
                    <v-col cols="3" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Building Access Confirmed?"
                      v-model="requiredFields.BuildingAccessAssigned"
                      :rules="rulesIsRequired"
                    ></v-switch></v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row v-if="!workflow.RequiresBuildingAccess">
                    <v-col><div><p class="text-body-1 font-weight-thin mt-3 mb-7 ml-3">No Office Workspace is Required</p></div></v-col>
                  </v-row>
                  <v-row v-if="workflow.RequiresOfficeWorkspace">
                    <v-col cols="3" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Workstation Assigned?"
                      v-model="requiredFields.OfficeWorkspaceAssigned"
                      :rules="rulesIsRequired"
                    ></v-switch></v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row v-if="!workflow.RequiresOfficeWorkspace">
                    <v-col><div><p class="text-body-1 font-weight-thin mt-3 mb-7 ml-3">No Workstation is Required</p></div></v-col>
                  </v-row>
                  <v-row v-if="workflow.RequiresParking">
                    <v-col cols="3" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Parking Space Assigned?"
                      v-model="requiredFields.ParkingAssigned"
                      :rules="rulesIsRequired"
                    ></v-switch></v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row v-if="!workflow.RequiresParking">
                    <v-col><div><p class="text-body-1 font-weight-thin mt-3 mb-7 ml-3">No Parking Space is Required</p></div></v-col>
                  </v-row>
                </div>
                <div v-if="workflow.WorkflowPageName === 'workflow/staff-new-manager'">
                  <v-row>
                    <span class="text-h6 text-primary ml-4">Manager Checklist</span>
                  </v-row>
                  <v-row>
                    <v-divider class="mb-6"></v-divider>
                  </v-row>
                  <v-row v-if="workflow.ManagerID">
                    <v-col class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="New Staff Member Confirmed?"
                      v-model="requiredFields.StaffMemberAssigned"
                      :rules="rulesIsRequired"
                    ></v-switch></v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row>
                    <span class="text-h6 text-primary ml-4">IT Requirements</span>
                  </v-row>
                  <v-row>
                    <v-divider class="mb-6"></v-divider>
                  </v-row>
                  <v-row>
                    <v-col class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Requires Telephone Number?"
                      v-model="requiredFields.RequiresTelephone"
                    ></v-switch></v-col>
                    <v-col class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Requires Email Address?"
                      v-model="requiredFields.RequiresEmailAddress"
                    ></v-switch></v-col>
                    <v-col class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Requires Workstation?"
                      v-model="requiredFields.RequiresLaptop"
                    ></v-switch></v-col>
                  </v-row>
                  <v-row>
                    <v-col class="ml-3"><v-textarea
                      density="compact"
                      persistent-placeholder
                      variant="underlined"
                      clearable
                      label="Special Requests (IT)"
                      prepend-inner-icon="mdi-comment"
                      v-model="requiredFields.SpecialRequestsIT"
                      counter
                      maxlength="1500"
                    ></v-textarea></v-col>
                  </v-row>
                  <v-row>
                    <span class="text-h6 text-primary ml-4">Office Requirements</span>
                  </v-row>
                  <v-row>
                    <v-divider class="mb-6"></v-divider>
                  </v-row>
                  <v-row>
                    <v-col class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Requires Office/Desk Space?"
                      v-model="requiredFields.RequiresOfficeWorkspace"
                    ></v-switch></v-col>
                    <v-col><v-switch
                      density="compact"
                      color="primary"
                      label="Requires Building Access?"
                      v-model="requiredFields.RequiresBuildingAccess"
                    ></v-switch></v-col>
                    <v-col class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Requires Parking Space?"
                      v-model="requiredFields.RequiresParking"
                    ></v-switch></v-col>
                  </v-row>
                  <v-row>
                    <v-col class="ml-3"><v-textarea
                      density="compact"
                      persistent-placeholder
                      variant="underlined"
                      clearable
                      label="Special Requests (Office)"
                      prepend-inner-icon="mdi-comment"
                      v-model="requiredFields.SpecialRequestsOffice"
                      counter
                      maxlength="1500"
                    ></v-textarea></v-col>
                  </v-row>
                </div>
              </v-form>
            </div>
          </v-card-text>
            <v-card-actions v-if="!workflow.IsComplete">
              <v-spacer></v-spacer>
              <v-btn color="success" :disabled="!!workflow.AssignedUserID" variant="text" @click="assignWorkflow()">Assign</v-btn>
              <v-btn color="primary" :disabled="!isFormValid || (workflow.AssignedUserID != userID) || completeInProgress" variant="text" @click="saveAndCompleteWorkflow()">Save And Complete</v-btn>
            </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
    <progressdialog v-model="completeInProgress" message="Completing workflow, please hold..."></progressdialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import authService from '@/services/authService'
import utils from '@/services/utils'
import { useDate } from 'vuetify'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])

const router = useRouter()
const route = useRoute()
const dateAdapter = useDate()

const workflowNotificationID = route.params.id
const today = dateAdapter.toISO(new Date())
const userID = localStorage.getItem("user_id")

const workflowForm = ref(null)
var isFormValid = ref(true)
var completeInProgress = ref(false)

var workflow = ref({})
var requiredFields = ref({})

//Validations
const rulesIsRequired = [v => !!v || "Value is required"]
const rulesIsEmailAddress = [ v => (!!v && /^.+@.+\..+$/.test(v)) || "Invalid Email Address (user@domain.com)" ]

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

function formattedDate(selectedDate) {
  return utils.formatDatePeriod(selectedDate, true)
}

function sendMail(emailAddress) {
  window.open(`mailto:${emailAddress}`)
  updateResultMessage("success|Sending Mail|" + emailAddress)
}

async function checkAuthorization(userID) {
  let isAuthorized = false
  
  if ([1, 2, 3].includes(workflow.value.WorkflowTypeID)) {
    isAuthorized = (userID == localStorage.getItem("user_id")) ||
      (await authService.hasRoleID(workflow.value.RoleID))
  }
  else {
    isAuthorized = false
  }

  if (!isAuthorized) {
    updateResultMessage("error|Not Authorized|You are not authorized to view this Workflow")
    router.push({
      name: "not-authenticated"
    })
  }
}

async function getWorkflow() {
  await utils.httpRequest(`/Workflows/${workflowNotificationID}`, utils.REQUEST.GET, (data) => {
    workflow.value = data
  })
  await checkAuthorization(workflow.value.AssignedUserID)
}

async function assignWorkflow() {
  try {
    let payload = { UserID: userID }
    await utils.httpRequest(`/Workflows/${workflowNotificationID}/Assign`, utils.REQUEST.POST, (data) => {
        if (data.Error) {
          throw new Error(data.Error.Message)
        }
      },
      payload
    )
    updateResultMessage("success|Assign Workflow|Successfully assigned to you")
    await getWorkflow()
  } catch (e) {
    updateResultMessage("error|Could not assign workflow|" + workflowNotificationID + " - " + e.message)
  }
}

async function saveAndCompleteWorkflow() {
  completeInProgress.value = true
  try {
    let payload = requiredFields.value
    await utils.httpRequest(`/Workflows/${workflowNotificationID}/Complete`, utils.REQUEST.POST, (data) => {
        if (data.Error) {
          throw new Error(data.Error.Message)
        }
      },
      payload
    )
    updateResultMessage("success|Complete Workflow|Workflow successfully completed")
    router.push({
      name: "staff-profile"
    })
  } catch (e) {
    updateResultMessage("error|Could not complete workflow|" + workflowNotificationID + " - " + e.message)
  }
  completeInProgress.value = false
}

onMounted(async () => {
  await getWorkflow()

  emit("updateBreadcrumb", ["Workflow", workflow.value.WorkflowTypeName, workflow.value.Summary])
  emit("updateHeader", workflow.value.WorkflowTypeName)
  if(workflowForm.value) {
    await workflowForm.value.validate()
  }
})
</script>