<template>
  <div class="ml-3">
    <v-row class="mt-1 mr-3">
      <v-col>
        <v-card
          class="mb-4 d-flex flex-column"
          rounded="xl"
          style="overflow-y: auto"
          elevation="4">
          <v-card-title class="bg-secondary">
            <v-col>
              <v-icon icon="mdi-account-plus" size="large"></v-icon>
              New User Request - User Details
            </v-col>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-form v-model="isNewUserValid">
      <v-row no-gutters>
        <v-col>
          <v-card
            class="mt-0 mb-6 mr-6 d-flex flex-column"
            rounded="xl"
            elevation="4"
            style="overflow-y: auto"
          >
            <v-card-text
              class="mt-6">
              <v-row>
                <v-col><v-text-field
                  density="compact"
                  persistent-placeholder
                  variant="underlined"
                  label="First Names"
                  v-model="newUser.FirstNames"
                  :rules="nameRules"
                /></v-col>
                <v-col><v-text-field
                  density="compact"
                  persistent-placeholder
                  variant="underlined"
                  label="Preferred Name"
                  v-model="newUser.PreferredName"
                /></v-col>
                <v-col><v-text-field
                  density="compact"
                  persistent-placeholder
                  variant="underlined"
                  label="Last Names"
                  v-model="newUser.LastNames"
                  :rules="nameRules"
                /></v-col>
              </v-row>
              <v-row>
                <span class="text-h6 text-primary ml-4">Employment Details</span>
              </v-row>
              <v-row>
                <v-divider class="pb-4"></v-divider>
              </v-row>
              <v-row>
                <v-col><datepicker
                  density="compact"
                  color="secondary"
                  variant="underlined"
                  label="Start Date"
                  v-model="newUser.StartDate"
                ></datepicker></v-col>
                <v-col><v-text-field
                  density="compact"
                  color="primary"
                  persistent-placeholder
                  variant="underlined"
                  label="Months of Probation"
                  type="number"
                  min="0"
                  max="12"
                  v-model="newUser.ProbationMonths"
                  :rules="nameRules"
                /></v-col>
                <v-col></v-col>
              </v-row>
              <v-row>
                <v-col><v-autocomplete
                  density="compact"
                  persistent-placeholder
                  variant="underlined"
                  label="Country"
                  v-model="newUser.CountryID"
                  :rules="nameRules"
                  :items="lookups.Countries"
                  :item-title="item => `${item.CountryName} (${item.CountryCode})`"
                  :item-value="item => item.CountryID"
                /></v-col>
                <v-col><v-autocomplete
                  density="compact"
                  persistent-placeholder
                  variant="underlined"
                  label="Office"
                  v-model="newUser.OfficeID"
                  :rules="nameRules"
                  :items="lookups.Offices"
                  :item-title="item => `${item.OfficeName} (${getCountryCode(item.CountryID)})`"
                  :item-value="item => item.OfficeID"
                /></v-col>
                <v-col></v-col>
              </v-row>
              <v-row>
                <v-col><v-text-field
                  density="compact"
                  persistent-placeholder
                  variant="underlined"
                  label="Job Title"
                  v-model="newUser.JobTitle"
                  :rules="nameRules"
                /></v-col>
                <v-col><v-autocomplete
                  density="compact"
                  persistent-placeholder
                  variant="underlined"
                  label="Manager"
                  v-model="newUser.ManagerID"
                  :rules="nameRules"
                  :items="lookups.Managers"
                  :item-title="item => `${item.Name} (${item.CompanyCode})`"
                  :item-value="item => item.UserID"
                /></v-col>
                <v-col><v-autocomplete
                  density="compact"
                  persistent-placeholder
                  variant="underlined"
                  label="Employee Type"
                  v-model="newUser.EmployeeTypeID"
                  :rules="nameRules"
                  :items="lookups.EmployeeTypes"
                  :item-title="item => item.EmployeeTypeName"
                  :item-value="item => item.EmployeeTypeID"
                /></v-col>
              </v-row>
              <v-row>
                <v-col><v-autocomplete
                  density="compact"
                  persistent-placeholder
                  variant="underlined"
                  label="Company"
                  :loading="isLoadingCompany"
                  v-model="newUser.CompanyID"
                  :rules="nameRules"
                  :items="lookups.Companies"
                  :item-title="item => `${item.CompanyName} (${item.CompanyCode})`"
                  :item-value="item => item.CompanyID"
                /></v-col>
                <v-col><v-autocomplete
                  density="compact"
                  persistent-placeholder
                  variant="underlined"
                  label="Department"
                  v-model="newUser.DepartmentID"
                  :rules="nameRules"
                  :items="lookups.Departments"
                  :item-title="item => `${item.DepartmentName} (${item.DepartmentCode})`"
                  :item-value="item => item.DepartmentID"
                /></v-col>
                <v-col><v-autocomplete
                  density="compact"
                  persistent-placeholder
                  variant="underlined"
                  label="Silo"
                  v-model="newUser.SiloID"
                  :rules="nameRules"
                  :items="lookups.Silos"
                  :item-title="item => `${item.SiloName} (${item.SiloCode})`"
                  :item-value="item => item.SiloID"
                /></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-card
            class="mt-0 mb-6 mr-6 d-flex flex-column"
            rounded="xl"
            elevation="4"
            style="overflow-y: auto"
          >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                variant="tonal"
                class="ma-3"
                color="success"
                :disabled="!isNewUserValid"
                @click="saveNewUser()"
              >Create</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
  <v-dialog v-model="saveInProgress" width="750px">
    <v-progress-linear
      class="mb-4"
      :active="saveInProgress"
      color="primary"
      height="4"
      indeterminate
    ></v-progress-linear>
    <v-sheet
    class="d-flex justify-center">
    Creating onboarding workflows for {{ newUser.FirstNames }} {{ newUser.LastNames }}...
    </v-sheet>
  </v-dialog>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import authService from '@/services/authService'
import utils from '@/services/utils'
import { useDate } from 'vuetify'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])

const router = useRouter()
const dateAdapter = useDate()

const today = dateAdapter.toISO(new Date())

const newUserConst = ref({
  FirstNames: null,
  LastNames: null,
  CountryID: null,
  CompanyID: null,
  DepartmentID: null,
  SiloID: null,
  ManagerID: null,
  StartDate: today,
  ProbationMonths: 3,
})

var newUser = newUserConst

var isNewUserValid = ref(false)
const saveInProgress = ref(false)
var lookups = ref({
  Companies: [],
  Countries: [],
  Departments: [],
  Silos: [],
  Offices: [],
  EmployeeTypes: [],
  Genders: [],
  MaritalStatuses: [],
  Managers: [],
})

const isAdmin = ref(false)
const isCompanyAdmin = ref(false)

const isLoadingCompany = ref(false)
//Validations
const nameRules = [(v) => !!v || "Value is required"]

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

function getCountryCode(countryID) {
  const country = Array.isArray(lookups.value["Countries"]) ? lookups.value["Countries"].find(c => c.CountryID === countryID) : lookups.value["Countries"]
  return country ? country.CountryCode : countryID
}

async function getManagerLookup(ManagerID) {
  let uri = '/Users'
  if(ManagerID != -1) {
    uri = uri + '/' + ManagerID + '/Basic'
  }
  await utils.httpRequest(uri, utils.REQUEST.GET, (data) => {
    if (ManagerID == -1) {
      lookups.value["Managers"] = data.filter(function(item) {
        if (!item.hasOwnProperty('IsActive')) return true
        if (item.IsActive === true) return true
        return false
      }).sort(function(a, b) {
        var x = a["Name"]
        var y = b["Name"]
        return ((x < y) ? -1 : ((x > y) ? 1 : 0))
      })
    } else {
      lookups.value["Managers"] = [data]
    }
  })
}

async function getCompaniesLookup(lookupID) {
  isLoadingCompany.value = true
  let variableName = 'Companies'
  let uri = '/Static/' + variableName
  if (lookupID) {
    if(lookupID != -1) {
      uri = uri + '/' + lookupID
    }
    await utils.httpRequest(uri, utils.REQUEST.GET, (data) => {
      if (lookupID == -1) {
        lookups.value[variableName] = data.filter(function(item) {
          let cadmin = (isCompanyAdmin.value && authService.isAdminForCompany(item.CompanyID))
          let isactive = (item.hasOwnProperty('IsActive') && item.IsActive === true)
          return (isAdmin.value || cadmin) && isactive
        }).sort(function(a, b) {
          var x = a["CompanyName"]
          var y = b["CompanyName"]
          return ((x < y) ? -1 : ((x > y) ? 1 : 0))
        })
      } else {
        lookups.value[variableName] = [data]
      }
    })
    let nameKey
    for (let key in lookups.value[variableName][0]) {
      if (key.endsWith("Name")) {
        nameKey = key
        break
      }
    }
    lookups.value[variableName] = lookups.value[variableName].sort(function(a, b) {
    var x = a[nameKey]
    var y = b[nameKey]
    return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  }

  isLoadingCompany.value = false
}

async function getLookup(variableName, lookupID) {
  let uri = '/Static/' + variableName
  if (lookupID) {
    if(lookupID != -1) {
      uri = uri + '/' + lookupID
    }
    await utils.httpRequest(uri, utils.REQUEST.GET, (data) => {
      if (lookupID == -1) {
        lookups.value[variableName] = data.filter(function(item) {
          if (!item.hasOwnProperty('IsActive')) return true
          if (item.IsActive === true) return true
          return false
        })
      } else {
        lookups.value[variableName] = [data]
      }
    })
    let nameKey
    for (let key in lookups.value[variableName][0]) {
      if (key.endsWith("Name")) {
        nameKey = key
        break
      }
    }
    lookups.value[variableName] = lookups.value[variableName].sort(function(a, b) {
    var x = a[nameKey]
    var y = b[nameKey]
    return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  }
}

async function getLookups() {
  await getCompaniesLookup(-1)
  await getLookup("Countries", -1)
  await getLookup("Departments", -1)
  await getLookup("Silos", -1)
  await getLookup("Offices", -1)
  await getLookup("Genders", -1)
  await getLookup("MaritalStatuses", -1)
  await getLookup("EmployeeTypes", -1)
  await getManagerLookup(-1)
}

async function saveNewUser() {
  saveInProgress.value = true
  try {
    let payload = newUser.value
    payload["RequestedByUserID"] = localStorage.getItem("user_id")
    let newUserID
    await utils.httpRequest(`/Workflows/UserOnboarding`, utils.REQUEST.POST, (data) => {
        if (data.Error) {
          throw new Error(data.Error.Message)
        } else {
          newUserID = data[0].UserID
        }
      },
      payload
    )
    updateResultMessage("success|New User Requested|New User Workflows have been created")
    router.push({
      name: "staff-profile",
      params: { id: newUserID },
    })
  } catch (e) {
    saveInProgress.value = false
    updateResultMessage("error|New User Request|" + e.message)
  }
}

onMounted(async () => {
  isAdmin.value = (await authService.isHumanCapitalAdmin())
  isCompanyAdmin.value = (await authService.isCompanyAdmin())

  let isAuthorized = isAdmin.value || isCompanyAdmin.value

  if (!isAuthorized) {
    updateResultMessage("error|Not Authorized|You are not authorized to add new Staff")
    router.push({
      name: "not-authenticated"
    })
  }

  emit("updateBreadcrumb", ["User Profile", "New User"])
  emit("updateHeader", "New User")

  await getLookups()
})
</script>