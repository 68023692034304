<template><div>
<v-row>
  <v-col>
    <v-card
      class="mt-0 mb-6 ml-2 mr-2 mt-4 d-flex flex-column"
      rounded="xl"
      elevation="4"
      style="overflow-y: auto"
      min-height="620"
    >
      <v-card-text>
        <v-expansion-panels
          rounded="xl">
          <v-expansion-panel
            static
            color="secondary">
            <v-expansion-panel-title>
              <v-row>
                <v-col class="d-flex justify-end">
                  Filters and Options
                </v-col>
              </v-row>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              class="mb-n4">
              <v-row>
                <v-col></v-col>
                <v-col class="d-flex justify-end">
                  <xlsxexportbutton class="mr-2" :rawJSON="filteredItems" :updateMessage="updateResultMessage"></xlsxexportbutton>
                  <v-btn
                    variant="tonal"
                    color="primary"
                    icon=""
                    @click="addNew()"
                  >
                    <v-icon icon="mdi-plus-thick" />
                    <v-tooltip
                      activator="parent"
                      location="top"
                    >Add New
                    </v-tooltip>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="mb-6 pb-10"></div>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    v-model="selectedCompanyGroup"
                    label="Company Group"
                    density="compact"
                    auto-select-first
                    clearable
                    :items="companyGroupLookup"
                    :item-title="item => `${item.CompanyGroupName}`"
                    :item-value="item => item.CompanyGroupID"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    v-model="selectedCountry"
                    label="Country"
                    density="compact"
                    auto-select-first
                    clearable
                    :items="countryLookup"
                    :item-title="item => `${item.CountryName} (${item.CountryCode})`"
                    :item-value="item => item.CountryID"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="2"><div class="d-flex justify-end">
                  <v-switch
                    v-model="selectedIsActive"
                    variant="solo"
                    label="Is Active?"
                    density="compact"
                    color="primary"
                  >
                  </v-switch>
                </div></v-col>
                <v-col justify="end">
                  <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                    density="compact"
                    single-line
                    hide-details
                    clearable
                    ></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-data-table
          density="compact"
          :loading="isLoading"
          :headers="lookupHeaders"
          :items="filteredItems"
          :item-value="lookupPrimaryKey"
          items-per-page="-1"
          hide-default-footer="true">
          <template v-slot:loading>
            <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
          </template>
          <template v-slot:item.IsActive="{ item }">
            <v-icon
              v-if="item.IsActive"
              size="small"
              icon="mdi-check"
              color="success"
            ></v-icon>
            <v-icon
              v-if="!item.IsActive"
              size="small"
              icon="mdi-cancel"
              color="error"
            ></v-icon>
          </template>
          <template v-slot:item.CountryID="{ item }">
            {{ getCountryName(item.CountryID) }}
          </template>
          <template v-slot:item.CompanyGroupID="{ item }">
            {{ getCompanyGroupName(item.CompanyGroupID) }}
          </template>
          <template v-slot:item.CompanyAddress="{ item }">
            <v-btn
              size="small"
              :color="item.CompanyAddress ? `primary` : `warning`"
              icon=""
              variant="text"
              density="compact"
              @click="openGoogleMapsUrl(item.CompanyAddress)"
            ><v-icon icon="mdi-map-marker" />
             <v-tooltip
               activator="parent"
               location="top"
             >{{ formatAddress(item.CompanyAddress) }}
             </v-tooltip></v-btn>
          </template>
          <template v-slot:item.actions="{ item }">
            <div>
              <v-btn
                color="primary"
                icon=""
                variant="plain"
                density="compact"
                @click="showEdit(item.CompanyID)"
              ><v-icon size="sm" icon="mdi-eye" />
                <v-tooltip
                  activator="parent"
                  location="top"
                >View {{  item.Name }}
                </v-tooltip>
              </v-btn>
              <v-btn
                color="primary"
                icon=""
                variant="plain"
                density="compact"
                @click="routeToStaffList(item.CompanyID)"
              ><v-icon size="sm" icon="mdi-account-arrow-right" />
                <v-tooltip
                  activator="parent"
                  location="top"
                >Show {{  item.Name }} in Staff List
                </v-tooltip>
              </v-btn>
            </div>
          </template>
          <template v-slot:bottom> </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>
<v-row><v-col></v-col></v-row>

<v-dialog v-model="showDialogEditItem" width="750px">
  <v-card>
    <v-card-title>Edit {{ lookupNameReadable }}</v-card-title>
    <v-card-text>
      <v-row><v-col></v-col></v-row>
      <v-row>
        <v-col class="pa-0 ma-1" cols="2"><v-text-field
          density="compact"
          persistent-placeholder
          variant="plain"
          readonly
          type="number"
          label="ID"
          v-model="editItem.CompanyID"
        /></v-col>
        <v-col>
          <div class="d-flex justify-end mt-n6"><v-switch
            density="compact"
            color="primary"
            label="Is Active?"
            v-model="editItem.IsActive"
          ></v-switch></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0 ma-1" cols="2"><v-text-field
          density="compact"
          persistent-placeholder
          variant="underlined"
          label="Company Code"
          v-model="editItem.CompanyCode"
        /></v-col>
        <v-col class="pa-0 ma-1"><v-text-field
          density="compact"
          persistent-placeholder
          variant="underlined"
          label="Company Name"
          v-model="editItem.CompanyName"
        /></v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0 ma-1"><v-autocomplete
          density="compact"
          persistent-placeholder
          variant="underlined"
          label="Country Name"
          v-model="editItem.CountryID"
          :items="countryLookup"
          :item-title="item => `${item.CountryName} (${item.CountryCode})`"
          :item-value="item => item.CountryID"
        /></v-col>
        <v-col class="pa-0 ma-1"><v-autocomplete
          density="compact"
          persistent-placeholder
          variant="underlined"
          label="Company Group"
          v-model="editItem.CompanyGroupID"
          :items="companyGroupLookup"
          :item-title="item => item.CompanyGroupName"
          :item-value="item => item.CompanyGroupID"
        /></v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0 ma-1">
          <v-textarea
            density="compact"
            persistent-placeholder
            variant="underlined"
            no-resize
            rows="4"
            label="Company Address"
            v-model="editItem.CompanyAddress"
          />
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col class="text-primary">
          Company Administrators
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col>
          <v-chip v-for="r in companyAdmins"
            class="ml-2 mr-2 mb-2"
            :color="getCompanyAdminColour(r)"
            :key="r.UserID"
            closable
            @click:close=removeFromCompanyAdmins(r.UserID)
            label>
            <v-icon icon="mdi-label" start></v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >{{ r.EmailAddress }} {{ r.UserIsActive === false ? ' (User Not Active)' : ''}}
            </v-tooltip>
            {{ r.Name }} ({{ r.UserCompanyCode }})
          </v-chip>
          <v-chip v-if="companyAdmins.length === 0"
            class="ml-2 mr-2"
            label>
            <v-icon icon="mdi-label" start></v-icon>
            No Company Admins Assigned
          </v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            density="compact"
            persistent-placeholder
            variant="underlined"
            label="Available Users"
            clearable
            :loading="availableUsersLoading"
            v-model="selectedAvailableUser"
            :items="filteredAvailableUsers"
            :item-title="item => `${item.Name} (${item.CompanyCode})`"
            :item-value="item => item.UserID"
          />
        </v-col>
        <v-col cols="1">
          <v-btn
            size="x-large" 
            color="primary"
            icon=""
            variant="plain"
            density="compact"
            :disabled="!selectedAvailableUser"
            @click="addToCompanyAdmins()"
          >
            <v-icon color="primary" size="x-large" icon="mdi-chevron-up" />
            <v-tooltip
              activator="parent"
              location="top"
            >Add User
            </v-tooltip>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        @click="saveEditItem(editItem.CompanyID)"
      >Save</v-btn>
      <v-btn
        color="error"
        @click="cancelEditItem()"
      >Cancel</v-btn>
    </v-card-actions>
  </v-card>

</v-dialog>

</div></template>
<script setup>
import { ref, watch, onMounted, toRaw } from 'vue'
import authService from '@/services/authService'
import utils from '@/services/utils'
import { useDate } from 'vuetify'
import { useRouter } from 'vue-router'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const router = useRouter()

const props = defineProps({
  updateMessage: Function
})
var search = ref(null)
var filteredItems = ref([])
var selectedIsActive = ref(true)
var selectedCountry = ref(null)
var selectedCompanyGroup = ref(null)

var availableUsers = ref([])
var filteredAvailableUsers = ref([])
var selectedAvailableUser = ref(null)
var availableUsersLoading = ref(true)

const lookupPrimaryKey = "CompanyID"
const lookupName = "Companies"
const lookupNameReadable = "Companies"
const lookupHeaders = [
  { title: "", key: "IsActive", value: "IsActive", align: "center", width: "50px" },
  { title: "ID", key: "CompanyID", value: "CompanyID", width: "90px"},
  { title: "Code", key: "CompanyCode", value: "CompanyCode", width: "100px" },
  { title: "Name", key: "CompanyName", value: "CompanyName" },
  { title: "Country", key: "CountryID", value: "CountryID" },
  { title: "Company Group", key: "CompanyGroupID", value: "CompanyGroupID", sortable: false },
  { title: "Address", key: "CompanyAddress", value: "CompanyAddress", align: "center" },
  { title: "Active Users", key: "ActiveUsers", value: "ActiveUsers", width: "140px" },
  { title: "Administrators", key: "AdminUsers", value: "AdminUsers", width: "140px" },
  { title: "Actions", key: "actions", width: "90px", sortable: false },
]

var editItem = ref({})
const companyAdmins = ref([])

var showDialogEditItem = ref(false)

const countryLookup = ref([])
const companyGroupLookup = ref([])

var lookupItems = ref([])
const isLoading = ref(false)

function updateResultMessage(message) {
  props.updateMessage(message)
}

function getCompanyAdminColour(item) {
  if (item.UserIsActive === false) {
    return "error"
  }
  else {
    return "primary"
  }
}

function getCountryName(countryID) {
  const country = Array.isArray(countryLookup.value) ? countryLookup.value.find(c => c.CountryID === countryID) : countryLookup.value
  return country ? `${country.CountryName} (${country.CountryCode})` : countryID
}

async function getCountryLookup() {
  await utils.httpRequest(`/Static/Countries/`, utils.REQUEST.GET, (data) => {
    countryLookup.value = data.filter(item => (item.IsActive == true))
  })
}

function getCompanyGroupName(companyGroupID) {
  const companyGroup = Array.isArray(companyGroupLookup.value) ? companyGroupLookup.value.find(c => c.CompanyGroupID === companyGroupID) : companyGroupLookup.value
  return companyGroup ? companyGroup.CompanyGroupName : companyGroupID
}

async function getCompanyGroupLookup() {
  await utils.httpRequest(`/Static/CompanyGroups/`, utils.REQUEST.GET, (data) => {
    companyGroupLookup.value = data.filter(item => (item.IsActive == true))
  })
}

async function getLookups() {
  await getCountryLookup()
  await getCompanyGroupLookup()
}

watch(selectedCompanyGroup, (newValue, oldValue) => {
  resetFilter()
})

watch(selectedCountry, (newValue, oldValue) => {
  resetFilter()
})

watch(selectedIsActive, (newValue, oldValue) => {
  resetFilter()
})

watch(search, (newValue, oldValue) => {
  resetFilter()
})

function resetFilter() {
  isLoading.value = true
  filteredItems.value = lookupItems.value.filter(item => {
    let isActiveMatch = selectedIsActive.value ? item.IsActive === selectedIsActive.value : true
    let isCountryMatch = selectedCountry.value ? item.CountryID === selectedCountry.value : true
    let isCompanyGroupMatch = selectedCompanyGroup.value ? item.CompanyGroupID === selectedCompanyGroup.value : true
    let textSearch = utils.jsonObjectHasText(item, search.value)
    return isActiveMatch && isCountryMatch && isCompanyGroupMatch && textSearch
  })
  isLoading.value = false
}

function filterAvailableUsers() {
  const filterUserIDs = companyAdmins.value.map(item => item.UserID);
  filteredAvailableUsers.value = availableUsers.value.filter(item => !filterUserIDs.includes(item.UserID))
  selectedAvailableUser.value = null
}

function addToCompanyAdmins() {
  let selectedObject = availableUsers.value.filter(item => item.UserID == selectedAvailableUser.value)
  let loadUser = selectedObject[0]
  loadUser.UserIsActive = selectedObject[0].IsActive
  loadUser.UserCompanyCode = selectedObject[0].CompanyCode

  companyAdmins.value.push(loadUser)

  filterAvailableUsers()
}

function removeFromCompanyAdmins(userID) {
  let idx = companyAdmins.value.findIndex(item => item.UserID === userID)
  companyAdmins.value.splice(idx, 1)

  filterAvailableUsers()
}

function routeToStaffList(lookupID) {
  const queryParams = {
    CompanyID: lookupID
  }

  const routeData = router.resolve({
      name: "staff-list",
      query: queryParams
    })
  window.open(routeData.href, "_blank")
}

async function getData() {
  isLoading.value = true
  await utils.httpRequest(`/Static/${lookupName}/`, utils.REQUEST.GET, (data) => {
    lookupItems.value = data
  })
  resetFilter()
}

async function getOneData(dataID) {
  await utils.httpRequest(`/Static/${lookupName}/${dataID}`, utils.REQUEST.GET, (data) => {
    editItem.value = data
  })
}

async function getUsers() {
  console.log("GETTING USERS")
  await utils.httpRequest(`/Users`, utils.REQUEST.GET, (data) => {
    availableUsers.value = data.filter(item => (item.IsActive == true))
  })
  filterAvailableUsers()
  availableUsersLoading.value = false
}


async function getCompanyAdmins(dataID) {
  companyAdmins.value = []
  await utils.httpRequest(`/Static/${lookupName}/${dataID}/Admins`, utils.REQUEST.GET, (data) => {
    companyAdmins.value = data
  })
}

async function showEdit(dataID) {
  await getOneData(dataID)
  await getCompanyAdmins(dataID)
  showDialogEditItem.value = true

  await getUsers()
}

async function addNew() {
  editItem.value = {}
  editItem.value.CompanyID = null
  editItem.value.IsActive = true
  showDialogEditItem.value = true

  await getUsers()
}

async function saveEditItem(dataID) {
  let payload = editItem.value
  let uri = `/Static/${lookupName}`
  let canContinue = false
  if (dataID) {
    uri = uri + `/${dataID}`
  }
  try {
    await utils.httpRequest(uri, utils.REQUEST.POST, (data) => {
      if (!data.Error) {
        canContinue = true
      } else {
        throw new Error(data.Error.Message)
      }
    },
    payload)

    if (canContinue) {
      let adminPayload = companyAdmins.value.map(item => ({"UserID": item.UserID}))
      await utils.httpRequest(uri + '/Admins', utils.REQUEST.POST, (data) => {
        if (!data.Error) {
          updateResultMessage("success|Saved|Saved Successfully", )
        }
        else {
          throw new Error(data.Error.Message)
        }
      },
      adminPayload)
    }

    showDialogEditItem.value = false
    await getData()
  } catch (e) {
      updateResultMessage("error|Save error|" + e.message)
  }
}

function cancelEditItem() {
  showDialogEditItem.value = false
}

function formatAddress(address) {
  return address ? address.replace(/\n/g, ", ") : "No address stored"
}

function openGoogleMapsUrl(address) {
  const formattedAddress = formatAddress(address)
  const encodedAddress = encodeURIComponent(formatAddress(formattedAddress));
  if (address) {
    window.open(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`, "_blank");
    updateResultMessage("success|Opening Google Maps|" + formattedAddress)
  }
  else {
    updateResultMessage("warning|Opening Google Maps|No address stored")
  }
}

onMounted(async () => {
  emit("updateBreadcrumb", ["Staff List"])
  emit("updateHeader", "Staff List")

  await getData()
  await getLookups()
})

</script>
