<template><div>
  <v-card
    border="secondary lg opacity-100"
    class="mx-auto d-flex flex-column"
    :height=props.height
    rounded="xl"
    variant="text"
  >
    <v-card-title class="bg-secondary"><v-icon icon="mdi-domain"></v-icon> Core Values</v-card-title>
    <v-card-text
      class="mt-3"
      style="overflow-y: auto"
    >
    <v-row>
      <v-col class="text-caption">
        <div class="font-weight-bold">DELIVER WORLD CLASS CUSTOMER SERVICE</div>
        <span>•<div class="mt-n5 ml-4">Place customers first – without customers we cease to exist</div></span>
        <span>•<div class="mt-n5 ml-4">Know our customers and understand their changing needs</div></span>
        <span>•<div class="mt-n5 ml-4">Treat our customers the same way we would like to be treated</div></span>
      </v-col>
      <v-col class="text-caption">
        <div class="font-weight-bold">NO EXCUSES</div>
        <span>•<div class="mt-n5 ml-4">Be proactive and resourceful – Find a Way!</div></span>
        <span>•<div class="mt-n5 ml-4">Deliver on our promises with integrity – be ‘results-driven’</div></span>
        <span>•<div class="mt-n5 ml-4">Be courageous, make the impossible possible</div></span>
      </v-col>
      <v-col class="text-caption">
        <div class="font-weight-bold">RAISE THE BAR</div>
        <span>•<div class="mt-n5 ml-4">Achieve the unthinkable</div></span>
        <span>•<div class="mt-n5 ml-4">Excellence in execution, benchmarked against world class standards</div></span>
        <span>•<div class="mt-n5 ml-4">Zero tolerance for mediocrity</div></span>
      </v-col>
      <v-col class="text-caption">
        <div class="font-weight-bold">PIONEERING</div>
        <span>•<div class="mt-n5 ml-4">Aggressively seek out new opportunities</div></span>
        <span>•<div class="mt-n5 ml-4">Build new businesses and financial markets</div></span>
        <span>•<div class="mt-n5 ml-4">See opportunity in adversity</div></span>
      </v-col>
      <v-col class="text-caption">
        <div class="font-weight-bold">PEOPLE FOCUS</div>
        <span>•<div class="mt-n5 ml-4">Treat colleagues with respect and dignity</div></span>
        <span>•<div class="mt-n5 ml-4">Use our talents and work in teams to pursue ideas</div></span>
        <span>•<div class="mt-n5 ml-4">Own our own growth and development</div></span>
      </v-col>
    </v-row>
    </v-card-text>
  </v-card>
</div></template>
<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import authService from '@/services/authService'
import utils from '@/services/utils'
import { useDate } from 'vuetify'
import { useRouter } from 'vue-router'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const props = defineProps({
  updateMessage: Function,
  height: String,
})

const dateAdapter = useDate()

function updateResultMessage(message) {
  props.updateMessage(message)
}

onMounted(async () => {
})
</script>
