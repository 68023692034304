<template><div>
  <v-card
    border="secondary lg opacity-100"
    class="mx-auto d-flex flex-column"
    :height=props.height
    rounded="xl"
    variant="text"
  >
    <v-card-title class="bg-secondary"><v-icon icon="mdi-link"></v-icon> Important Links</v-card-title>
    <v-card-text
      class="mt-3"
      style="overflow-y: auto"
    >
    <v-list
        density="compact"
        base-color="primary"
        variant="text"
      >
        <v-list-item
          v-for="(item, i) in linkList"
          @click="routeToURL(item.LinkURL)"
        >
          {{ item.LinkName }}
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</div></template>
<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import authService from '@/services/authService'
import utils from '@/services/utils'
import { useDate } from 'vuetify'
import { useRouter } from 'vue-router'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])
const props = defineProps({
  updateMessage: Function,
  height: String,
})

const dateAdapter = useDate()
const router = useRouter()

const today = dateAdapter.toISO(new Date())

const userID = localStorage.getItem("user_id")
const linkList = ref([])
const linkListHeaders = [
  { title: "Link", key: "LinkName", value: "LinkName"},
]

function formatDatePeriod(dt) {
  return utils.formatDatePeriod(dt, true, true, false)
}

function updateResultMessage(message) {
  props.updateMessage(message)
}

function routeToURL(linkURL) {
  window.open(linkURL)
  updateResultMessage("success|Opening Link|" + linkURL)
}
async function getLinkList() {
  await utils.httpRequest(`/Static/Links`, utils.REQUEST.GET, (data) => {
    linkList.value = data.filter(item => (item.IsActive == true))
  })
}

onMounted(async () => {
  await getLinkList()
})
</script>
