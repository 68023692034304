<template>
  <div class="d-flex align-center flex-column">
    <v-card
      prepend-icon="mdi-home"
      title="African Alliance Human Capital"
    >
      <v-card-text>
        Oops! We cannot find a profile for Profile ID <b>{{ route.params.id }}</b>.<br />
        Please contact your Human Capital Representative who will be able to correct the issue.
      </v-card-text>
    </v-card>
  </div>  
</template>
<script setup>
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])

const route = useRoute()
const router = useRouter()

onMounted(async () => {

})
</script>