// config.js
import { API_URL } from '@/constants'

export const msalConfig = {
  auth: {
      clientId: '6a08a6fb-6450-4168-a922-9e84720b916f',
      authority: 'https://login.microsoftonline.com/a12dbdfb-ddd9-4b3d-b435-cd5c4479e7a4',
      redirectUri: window.location.origin + '/auth/redirect',
      navigateToLoginRequestUrl: false,
    },
  cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
  },    
};
  
export const loginRequest = {
  scopes: ['api://6a08a6fb-6450-4168-a922-9e84720b916f/HumanCapital.Read'],
  prompt: "select_account",
  redirectUri: window.location.origin + '/auth/redirect'
};
  