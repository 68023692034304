<template>
  <div class="ml-3 mr-6">
    <v-expansion-panels
      rounded="xl">
      <v-expansion-panel
        static
        color="secondary">
        <v-expansion-panel-title>
          <v-row>
            <v-col class="d-flex justify-end">
              Filters and Options
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text
          class="mb-n4">
          <v-row>
            <v-col></v-col>
            <v-col class="d-flex justify-end">
              <xlsxexportbutton class="mr-2" :rawJSON="filteredItems" :updateMessage="updateResultMessage"></xlsxexportbutton>
              <v-btn
                variant="tonal"
                color="primary"
                icon=""
                @click="addNewStaff()"
              >
                <v-icon icon="mdi-account-plus" />
                <v-tooltip
                  activator="parent"
                  location="top"
                >Add New Staff
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-6 pb-10"></div>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="selectedCompany"
                label="Company"
                density="compact"
                auto-select-first
                clearable
                :items="companyLookup"
                :item-title="item => `${item.CompanyName} (${item.CompanyCode})`"
                :item-value="item => item.CompanyID"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="selectedCountry"
                label="Country"
                density="compact"
                auto-select-first
                clearable
                :items="countryLookup"
                :item-title="item => `${item.CountryName} (${item.CountryCode})`"
                :item-value="item => item.CountryID"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="1" class="d-flex justify-end">
              <v-switch
                v-model="selectedIsActive"
                variant="solo"
                label="Is Active?"
                density="compact"
                color="primary"
              >
              </v-switch>
            </v-col>
            <v-col justify="end">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                density="compact"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      density="compact"
      :loading="isLoading"
      :headers="userListHeaders"
      :items="filteredItems"
      item-value="UserID"
      items-per-page="25"
      >
      <template v-slot:loading>
        <v-skeleton-loader type="table-row@25"></v-skeleton-loader>
      </template>
      <template v-slot:item.Name="{ item }">
        <v-icon
        v-if="item.IsActive"
        size="sm"
        icon="mdi-check"
        color="success"
      ></v-icon>
      <v-icon
        v-if="! item.IsActive"
        size="sm"
        icon="mdi-cancel"
        color="error"
      ></v-icon>
      {{  item.Name }}
      </template>
      <template v-slot:item.EmailAddress="{ item }">
        <v-btn
          variant="text"
          size="sm"
          color="secondary"
          icon=""
          @click="sendMail(item.EmailAddress)"
          ><v-icon icon="mdi-email" />
            <v-tooltip
              activator="parent"
              location="top"
            >Send mail to {{ item.EmailAddress }}
            </v-tooltip>
          </v-btn>
        {{ item.EmailAddress }}
      </template>
      <template v-slot:item.CompanyID="{ item }">
        {{ getCompanyName(item.CompanyID) }}
      </template>
      <template v-slot:item.CountryID="{ item }">
        {{ getCountryName(item.CountryID) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div>
          <v-btn
            color="primary"
            icon=""
            variant="plain"
            density="compact"
            @click="routeToUserProfile(item.UserID)"
          ><v-icon size="sm" icon="mdi-eye" />
            <v-tooltip
              activator="parent"
              location="top"
            >View {{  item.Name }}
            </v-tooltip>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>

</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import authService from '@/services/authService'
import utils from '@/services/utils'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])

const route = useRoute()
const router = useRouter()

const userListHeaders = [
  { title: "Name", key: "Name", value: "Name"},
  { title: "Email Address", key: "EmailAddress", value: "EmailAddress" },
  { title: "Company", key: "CompanyID", value: "CompanyID", sortable: false },
  { title: "Country", key: "CountryID", value: "CountryID", sortable: false },
  { title: "Invalid Data Points", key: "InvalidDataPoints", value: "InvalidDataPoints", sortable: true },
  { title: "Actions", key: "actions", width: "90px", sortable: false },
]
var userList = ref([])

var companyLookup = ref([])
var countryLookup = ref([])

const isLoading = ref(true)

var search = ref(null)
var filteredItems = ref([])
var selectedIsActive = ref(true)
var selectedCountry = ref(route.query.CountryID ? Number(route.query.CountryID) : null)
var selectedCompany = ref(route.query.CompanyID ? Number(route.query.CompanyID) : null)

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

watch(selectedIsActive, (newValue, oldValue) => {
  resetFilter()
})

watch(selectedCompany, (newValue, oldValue) => {
  resetFilter()
})

watch(selectedCountry, (newValue, oldValue) => {
  resetFilter()
})

watch(search, (newValue, oldValue) => {
  resetFilter()
})

function resetFilter() {
  filteredItems.value = userList.value.filter(item => {
    let isActiveMatch = selectedIsActive.value ? item.IsActive === selectedIsActive.value : true
    let isCountryMatch = selectedCountry.value ? item.CountryID === selectedCountry.value : true
    let isCompanyMatch = selectedCompany.value ? item.CompanyID === selectedCompany.value : true
    let textSearch = utils.jsonObjectHasText(item, search.value)
    return isActiveMatch && isCountryMatch && isCompanyMatch && textSearch
  })
  isLoading.value = false
}

function getCompanyName(companyID) {
  const record = companyLookup.value.find(item => item.CompanyID === companyID)
  return record ? record.CompanyName + " (" + record.CompanyCode + ")": companyID
}

function getCountryName(countryID) {
  const record = countryLookup.value.find(item => item.CountryID === countryID)
  return record ? record.CountryName + " (" + record.CountryCode + ")": countryID
}

async function getUsers() {
  isLoading.value = true
  await utils.httpRequest(`/Users`, utils.REQUEST.GET, (data) => {
      userList.value = data
  })
  resetFilter()
  isLoading.value = false
}

async function getCompanies() {
  await utils.httpRequest(`/Static/Companies/`, utils.REQUEST.GET, (data) => {
      companyLookup.value = data.sort(function(a, b) {
      var x = a["CompanyName"]
      var y = b["CompanyName"]
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  })
  resetFilter()
  companyLookup.value.push({"CompanyID": -1, "CompanyName": "Unknown", "CompanyCode": "NONE"})
}

async function getCountries() {
  await utils.httpRequest(`/Static/Countries/`, utils.REQUEST.GET, (data) => {
      countryLookup.value = data.sort(function(a, b) {
      var x = a["CountryName"]
      var y = b["CountryName"]
      countryLookup.value.push({"CountryID": -1, "CountryName": "Unknown", "CountryCode": "NONE"})
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
  })
}

function addNewStaff() {
  const routeData = router.resolve({
      name: "new-staff",
    })
  window.open(routeData.href, "_blank")
}

function routeToUserProfile(userID) {
  const routeData = router.resolve({
      name: "staff-profile",
      params: { id: userID },
    })
  window.open(routeData.href, "_blank")
}

function sendMail(emailAddress) {
  window.open(`mailto:${emailAddress}`)
  updateResultMessage("success|Sending Mail|" + emailAddress)
}

onMounted(async () => {
  let isAuthorized = await authService.isHumanCapitalAdmin() || await authService.isHumanCapitalIT() || await authService.isCompanyAdmin()

  if (!isAuthorized) {
    updateResultMessage("error|Not Authorized|You are not authorized to view the Staff List")
    router.push({
      name: "not-authenticated"
    })
  }

  emit("updateBreadcrumb", ["Staff List"])
  emit("updateHeader", "Staff List")

  await getCompanies()
  await getCountries()

  await getUsers()
})
</script>