<template>
  <div class="ml-3">
    <v-row>
      <v-col cols="1"></v-col>
      <v-col>
        <v-card>
          <v-card-title class="text-h5 text-primary mb-3">
            <div>
            {{ workflow.WorkflowTypeName }}
            </div>
            <div align-content="end" class="text-right mt-n6">
              WORKFLOW-{{ workflow.WorkflowNotificationID }}
            </div>
          </v-card-title>
          <v-card-subtitle class="text-h6 mb-4">Requested by
            <v-btn
              class="mt-n1"
              size="small"
              color="secondary"
              icon=""
              variant="text"
              density="compact"
              @click="sendMail(workflow.RequestedByUserEmailAddress)"
            ><v-icon icon="mdi-email" />
             <v-tooltip
               activator="parent"
               location="top"
             >Send mail to {{ workflow.RequestedByUserEmailAddress }}
             </v-tooltip></v-btn>
             {{ workflow.RequestedByUserName }}
          </v-card-subtitle>
          <v-card-text>
            <div class="mb-12 text-body-1"><v-sheet elevation="2" rounded class="px-4">
              <v-row>
                <v-col class="text-h5 text-center text-primary">{{ workflow.Summary }}</v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-bold">First Name:</v-col>
                <v-col cols="3">{{ workflow.FirstNames }}</v-col>
                <v-col class="font-weight-bold">Preferred Name:</v-col>
                <v-col cols="3">{{ workflow.PreferredName }}</v-col>
                <v-col class="font-weight-bold">Last Name:</v-col>
                <v-col cols="3">{{ workflow.LastNames }}</v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-bold">Email Address:</v-col>
                <v-col cols="5">{{ workflow.EmailAddress }}</v-col>
                <v-col class="font-weight-bold"></v-col>
                <v-col cols="3"></v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-bold">Start Date:</v-col>
                <v-col cols="4">{{ formattedDate(workflow.StartDate) }}</v-col>
                <v-col class="font-weight-bold">End Date:</v-col>
                <v-col cols="4">{{ formattedDate(workflow.EndDate) }}</v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-bold">Job Title:</v-col>
                <v-col cols="4">{{ workflow.JobTitle }}</v-col>
                <v-col class="font-weight-bold">Manager:</v-col>
                <v-col cols="4">
                  <v-btn
                    size="small"
                    color="secondary"
                    icon=""
                    variant="text"
                    density="compact"
                    @click="sendMail(workflow.ManagerEmailAddress)"
                  ><v-icon icon="mdi-email" />
                   <v-tooltip
                     activator="parent"
                     location="top"
                   >Send mail to {{ workflow.ManagerEmailAddress }}
                   </v-tooltip></v-btn>
                   {{ workflow.ManagerName }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-bold">Country:</v-col>
                <v-col cols="4">{{ workflow.CountryName }}</v-col>
                <v-col class="font-weight-bold">Office:</v-col>
                <v-col cols="4">{{ workflow.OfficeName }}</v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-bold">Company:</v-col>
                <v-col cols="4">{{ workflow.CompanyName }}</v-col>
                <v-col class="font-weight-bold"></v-col>
                <v-col cols="4"></v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-bold">Department:</v-col>
                <v-col cols="4">{{ workflow.DepartmentName }}</v-col>
                <v-col class="font-weight-bold">Silo:</v-col>
                <v-col cols="4">{{ workflow.SiloName }}</v-col>
              </v-row>
              <div>
                <v-row>
                  <v-col class="font-weight-bold mb-n4">Special Instructions:</v-col>
                </v-row>
                <v-row>
                  <v-col v-if="workflow.SpecialRequests">{{ workflow.SpecialRequests }}</v-col>
                  <v-col v-if="!workflow.SpecialRequests" class="font-italic">None</v-col>
                </v-row>
              </div>
              <div v-if="workflow.WorkflowPageName == 'workflow/staff-exit-it'">
                <v-row>
                  <v-col class="font-weight-bold mb-n4">Special Instructions (IT):</v-col>
                </v-row>
                <v-row>
                  <v-col v-if="workflow.SpecialRequestsIT">{{ workflow.SpecialRequestsIT }}</v-col>
                  <v-col v-if="!workflow.SpecialRequestsIT" class="font-italic">None</v-col>
                </v-row>
              </div>
              <div v-if="workflow.WorkflowPageName == 'workflow/staff-exit-office'">
                <v-row>
                  <v-col class="font-weight-bold mb-n4">Special Instructions (Office):</v-col>
                </v-row>
                <v-row>
                  <v-col v-if="workflow.SpecialRequestsOffice">{{ workflow.SpecialRequestsOffice }}</v-col>
                  <v-col v-if="!workflow.SpecialRequestsOffice" class="font-italic">None</v-col>
                </v-row>
              </div>
            </v-sheet></div>
            <div v-if="workflow.IsComplete" class="text-body-1 ml-6">
              This workflow has been marked as complete.
            </div>
            <div v-if="!workflow.IsComplete">
              <v-form ref="workflowForm" v-model="isFormValid">
                <div v-if="workflow.WorkflowPageName === 'workflow/staff-exit-it'">
                  <v-row>
                    <span class="text-h6 text-primary ml-4">IT Checklist</span>
                  </v-row>
                  <v-row>
                    <v-divider class="mb-6"></v-divider>
                  </v-row>
                  <v-row v-if="workflow.MailboxAutoReply">
                    <v-col cols="4" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Mailbox Auto-reply configured?"
                      v-model="requiredFields.MailboxAutoReply"
                      :rules="rulesIsRequired"
                    ></v-switch></v-col>
                    <v-col class="text-body-1 text-grey mt-2"><pre>{{ workflow.MailboxAutoReplyMessage }}</pre></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="User Access Revoked?"
                      v-model="requiredFields.UserAccessRevoked"
                      :rules="rulesIsRequired"
                    ></v-switch></v-col>
                    <v-col v-if="workflow.WeeksToKeepMailbox == 0" class="text-body-1 text-error">User to be deleted immediately</v-col>
                    <v-col v-else class="text-body-1 text-grey mt-2">User to be placed in <strong>{{ workflow.WeeksToKeepMailbox }}</strong> week bucket</v-col>
                  </v-row>
                  <v-row v-if="workflow.GrantAccessToMailbox">
                    <v-col cols="4" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Mailbox Access Granted?"
                      v-model="requiredFields.AccessToMailboxGranted"
                      :rules="rulesIsRequired"
                    ></v-switch></v-col>
                    <v-col class="text-body-1 text-grey mt-2">{{ workflow.GrantAccessToMailboxUserName }} ({{ workflow.GrantAccessToMailboxUserEmailAddress }})</v-col>
                  </v-row>
                  <v-row v-if="workflow.GrantAccessToMailbox">
                    <v-col cols="4" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="OneDrive Access Granted?"
                      v-model="requiredFields.AccessToOneDriveGranted"
                      :rules="rulesIsRequired"
                    ></v-switch></v-col>
                    <v-col class="text-body-1 text-grey mt-2">{{ workflow.GrantAccessToOneDriveUserName }} ({{ workflow.GrantAccessToOneDriveUserEmailAddress }})</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Laptop Collected?"
                      v-model="requiredFields.LaptopCollected"
                      :rules="rulesIsRequired"
                    ></v-switch></v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Telephone Extension Revoked?"
                      v-model="requiredFields.TelephoneRevoked"
                      :rules="rulesIsRequired"
                    ></v-switch></v-col>
                    <v-col></v-col>
                  </v-row>
                </div>
                <div v-if="workflow.WorkflowPageName === 'workflow/staff-exit-office'">
                  <v-row>
                    <span class="text-h6 text-primary ml-4">Office Checklist</span>
                  </v-row>
                  <v-row>
                    <v-divider class="mb-6"></v-divider>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Building Access Revoked?"
                      v-model="requiredFields.BuildingAccessRevoked"
                      :rules="rulesIsRequired"
                    ></v-switch></v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Parking Access Revoked?"
                      v-model="requiredFields.ParkingAssigned"
                      :rules="rulesIsRequired"
                    ></v-switch></v-col>
                    <v-col></v-col>
                  </v-row>
                </div>
                <div v-if="workflow.WorkflowPageName === 'workflow/staff-exit-manager'">
                  <v-row>
                    <span class="text-h6 text-primary ml-4">Manager Checklist</span>
                  </v-row>
                  <v-row>
                    <v-divider class="mb-6"></v-divider>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="ml-3 mt-2"><div
                      class="text-body-1 text-grey">End Date Confirmation
                    </div></v-col>
                    <v-col><datepicker
                      density="compact"
                      color="secondary"
                      label="End Date"
                      v-model="requiredFields.EndDate"
                    ></datepicker></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="ml-3 mt-2"><div
                      class="text-body-1 text-grey">Weeks to Keep Mail Active
                    </div></v-col>
                    <v-col><v-slider
                      v-model="requiredFields.WeeksToKeepMailbox"
                      :min="0"
                      :max="12"
                      :step="4"
                      thumb-label="always"
                      :color="(requiredFields.WeeksToKeepMailbox === 0) ? `error` : `secondary`"
                      type="number"
                      persistent-hint
                      :hint="(requiredFields.WeeksToKeepMailbox === 0) ? `If set to 0, the user will be deleted immediately`: null"
                    /></v-col>
                  </v-row>
                  <v-row v-if="requiredFields.WeeksToKeepMailbox > 0">
                    <v-col cols="3" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Grant Access To Mailbox?"
                      v-model="requiredFields.GrantAccessToMailbox"
                    ></v-switch></v-col>
                    <v-col><v-autocomplete
                      v-if="requiredFields.GrantAccessToMailbox"
                      density="compact"
                      persistent-placeholder
                      variant="underlined"
                      label="User for Mailbox Access"
                      clearable
                      v-model="requiredFields.GrantAccessToMailboxUserID"
                      :rules="rulesIsRequired"
                      :items="users"
                      :item-title="item => `${item.Name} (${item.UserName})`"
                      :item-value="item => item.UserID"
                    /></v-col>
                  </v-row>
                  <v-row v-if="requiredFields.WeeksToKeepMailbox > 0">
                    <v-col cols="3" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Grant Access To OneDrive?"
                      v-model="requiredFields.GrantAccessToOneDrive"
                    ></v-switch></v-col>
                    <v-col><v-autocomplete
                      v-if="requiredFields.GrantAccessToOneDrive"
                      density="compact"
                      persistent-placeholder
                      variant="underlined"
                      label="User for OneDrive Access"
                      clearable
                      v-model="requiredFields.GrantAccessToOneDriveUserID"
                      :items="users"
                      :item-title="item => `${item.Name} (${item.UserName})`"
                      :item-value="item => item.UserID"
                    /></v-col>
                  </v-row>
                  <v-row v-if="requiredFields.WeeksToKeepMailbox > 0">
                    <v-col cols="3" class="ml-3"><v-switch
                      density="compact"
                      color="primary"
                      label="Mailbox Auto reply?"
                      v-model="requiredFields.MailboxAutoReply"
                    ></v-switch></v-col>
                    <v-col><v-textarea
                      v-if="requiredFields.MailboxAutoReply"
                      density="compact"
                      persistent-placeholder
                      variant="underlined"
                      no-resize
                      rows="8"
                      label="Auto-reply Message"
                      v-model="requiredFields.MailboxAutoReplyMessage"
                      :rules="rulesIsRequired"
                    /></v-col>
                  </v-row>
                  <v-row>
                    <v-col><v-textarea
                      density="compact"
                      persistent-placeholder
                      variant="underlined"
                      no-resize
                      rows="4"
                      label="Special Instructions for IT"
                      v-model="requiredFields.SpecialRequestsIT"
                    /></v-col>
                  </v-row>
                  <v-row>
                    <v-col><v-textarea
                      density="compact"
                      persistent-placeholder
                      variant="underlined"
                      no-resize
                      rows="4"
                      label="Special Instructions for Office Manager"
                      v-model="requiredFields.SpecialRequestsffice"
                    /></v-col>
                  </v-row>
                </div>
              </v-form>
            </div>
          </v-card-text>
            <v-card-actions v-if="!workflow.IsComplete">
              <v-spacer></v-spacer>
              <v-btn color="success" :disabled="!!workflow.AssignedUserID" variant="text" @click="assignWorkflow">Assign</v-btn>
              <v-btn color="primary" :disabled="!isFormValid || (workflow.AssignedUserID != userID) || completeInProgress" variant="text" @click="saveAndCompleteWorkflow">Save And Complete</v-btn>
            </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
    <progressdialog v-model="completeInProgress" message="Completing workflow, please hold..."></progressdialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import authService from '@/services/authService'
import utils from '@/services/utils'
import { useDate } from 'vuetify'

const emit = defineEmits(["updateHeader", "updateBreadcrumb", "updateResultMessage"])

const router = useRouter()
const route = useRoute()
const dateAdapter = useDate()

const workflowNotificationID = route.params.id
const today = dateAdapter.toISO(new Date())
const userID = localStorage.getItem("user_id")

const workflowForm = ref(null)
var isFormValid = ref(true)
var completeInProgress = ref(false)

var workflow = ref({})
var requiredFields = ref({})

var users = ref([])

//Validations
const rulesIsRequired = [v => !!v || "Value is required"]
const rulesIsEmailAddress = [ v => (!v || /^.+@.+\..+$/.test(v)) || "Invalid Email Address (user@domain.com)" ]

function updateResultMessage(message) {
  emit("updateResultMessage", message)
}

function formattedDate(selectedDate) {
  return utils.formatDatePeriod(selectedDate, true)
}

function sendMail(emailAddress) {
  window.open(`mailto:${emailAddress}`)
  updateResultMessage("success|Sending Mail|" + emailAddress)
}

async function checkAuthorization(userID) {
  let isAuthorized = false

  if ([4, 5, 6].includes(workflow.value.WorkflowTypeID)) {
    isAuthorized = (userID == localStorage.getItem("user_id")) ||
      (await authService.hasRoleID(workflow.value.RoleID))
  }
  else {
    isAuthorized = false
  }

  if (!isAuthorized) {
    updateResultMessage("error|Not Authorized|You are not authorized to view this Workflow")
    router.push({
      name: "not-authenticated"
    })
  }
}

async function getUsers() {
  await utils.httpRequest(`/Users`, utils.REQUEST.GET, (data) => {
    users.value = data.filter(item => item.IsActive == true)
  })
}

async function getWorkflow() {
  await utils.httpRequest(`/Workflows/${workflowNotificationID}`, utils.REQUEST.GET, (data) => {
    workflow.value = data
  })
  await checkAuthorization(workflow.value.AssignedUserID)
}

async function assignWorkflow() {
  try {
    let payload = { UserID: userID }
    await utils.httpRequest(`/Workflows/${workflowNotificationID}/Assign`, utils.REQUEST.POST, (data) => {
        if (data.Error) {
          throw new Error(data.Error.Message)
        }
      },
      payload
    )
    updateResultMessage("success|Assign Workflow|Successfully assigned to you")
    await getWorkflow()
  } catch (e) {
    updateResultMessage("error|Could not assign workflow|" + workflowNotificationID + " - " + e.message)
  }
}

async function saveAndCompleteWorkflow() {
  completeInProgress.value = true
  try {
    let payload = requiredFields.value
    await utils.httpRequest(`/Workflows/${workflowNotificationID}/Complete`, utils.REQUEST.POST, (data) => {
        if (data.Error) {
          throw new Error(data.Error.Message)
        }
      },
      payload
    )
    updateResultMessage("success|Complete Workflow|Workflow successfully completed")
  } catch (e) {
    updateResultMessage("error|Could not complete workflow|" + workflowNotificationID + " - " + e.message)
  }
  completeInProgress.value = false

  router.push({
    name: "staff-profile"
  })
}

function setRequiredFieldsDefaults() {
  let workflowType = workflow.value.WorkflowPageName
  if (workflowType === 'workflow/staff-exit-manager') {
    requiredFields.value = {
      EndDate: workflow.value.ProposedEndDate,
      WeeksToKeepMailbox: 4,
      MailboxAutoReply: true,
      MailboxAutoReplyMessage: "I am no longer in the employ of " + workflow.value.CompanyName + ".\n\n" +
        "Please reach out to " + workflow.value.ManagerName + " at " + workflow.value.ManagerEmailAddress + " for any queries.\n\n" +
        "Regards,\n" +
        ((workflow.value.PreferredName) ? workflow.value.PreferredName : workflow.value.FirstNames) + " " + workflow.value.LastNames,
      GrantAccessToMailbox: true,
      GrantAccessToMailboxUserID: workflow.value.AssignedUserID,
      GrantAccessToOneDrive: true,
      GrantAccessToOneDriveUserID: workflow.value.AssignedUserID,
    }
  }
}

onMounted(async () => {
  await getWorkflow()
  setRequiredFieldsDefaults()
  await getUsers()

  emit("updateBreadcrumb", ["Workflow", workflow.value.WorkflowTypeName, workflow.value.Summary])
  emit("updateHeader", workflow.value.WorkflowTypeName)
  if(workflowForm.value) {
    await workflowForm.value.validate()
  }
})
</script>