<template><div>
  <v-row>
    <v-col>
      <v-progress-linear
        class="mb-4"
        :active="isLoading"
        color="primary"
        height="4"
        indeterminate
      ></v-progress-linear>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-file-input
        variant="solo"
        density="compact"
        :disabled="!isAuthorizedAdmin"
        label="Drop file or click to upload"
        v-model="newDocument.DocumentObject">
      </v-file-input>
    </v-col>
    <v-col cols="1">
      <v-btn
        variant="tonal"
        class="ma-1"
        color="primary"
        :disabled="!newDocument.DocumentObject || dialogUpload"
        @click="showUploadDialog"
      >Upload</v-btn>
      <v-dialog v-model="dialogUpload" max-width="750px">
      <v-form v-model="isUploadValid">
        <v-card>
          <v-card-title>
            Upload File
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <span class="font-weight-bold">{{ newDocument.DocumentObject.name }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="newSelectedDocumentCategory"
                  label="Document Categories"
                  density="compact"
                  clearable
                  :readonly="uploadInProgress"
                  :rules="rulesIsRequired"
                  :items="newDocumentCategories"
                  :item-title="item => item.DocumentCategoryName"
                  :item-value="item => item.DocumentCategoryID"
                />
              </v-col>
              <v-col>
                <v-select
                  v-model="newSelectedDocumentType"
                  label="Document Types"
                  density="compact"
                  clearable
                  :readonly="uploadInProgress"
                  :rules="rulesIsRequired"
                  :items="newFilteredDocumentTypes"
                  :item-title="item => item.DocumentTypeName"
                  :item-value="item => item.DocumentTypeID"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  density="compact"
                  persistent-placeholder
                  :readonly="uploadInProgress"
                  variant="underlined"
                  label="Document Description"
                  v-model="newDocument.DocumentDescription"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="text-center" v-if="uploadInProgress">
                  <v-progress-linear
                    class="mb-4"
                    :active="uploadInProgress"
                    color="primary"
                    height="4"
                    indeterminate
                  ></v-progress-linear>
                  <v-sheet
                  class="d-flex justify-center">
                  Uploading File, please do not close this window
                  </v-sheet>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" variant="text" :disabled="!isUploadValid || uploadInProgress" @click="saveUploadDialog()">Save</v-btn>
            <v-btn color="error" variant="text" :disabled="uploadInProgress" @click="cancelUploadDialog()">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog> 
    </v-col>
  </v-row>
  <v-row>
    <v-divider></v-divider>
  </v-row>
  <v-row>
    <v-col cols="3"></v-col>
    <v-col cols="3"><v-select
      v-model="selectedDocumentCategory"
      label="Document Categories"
      density="compact"
      clearable
      :items="documentCategories"
      :item-title="item => item.DocumentCategoryName"
      :item-value="item => item.DocumentCategoryID"
    /></v-col>
    <v-col cols="3"><v-select
      v-model="selectedDocumentType"
      label="Document Types"
      density="compact"
      clearable
      :items="filteredDocumentTypes"
      :item-title="item => item.DocumentTypeName"
      :item-value="item => item.DocumentTypeID"
    /></v-col>
    <v-col justify="end">
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Search"
        density="compact"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-col>
  </v-row>
  <v-data-table
    min-height="390"
    density="compact"
    :loading="isLoading"
    v-model:search="search"
    :headers="userDocumentsHeaders"
    :items="filteredUserDocuments"
    items-per-page="-1"
    hide-default-footer="true">
    <template v-slot:loading>
      <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
    </template>
    <template v-slot:item.actions="{ item }">
      <div>
        <v-btn
          color="primary"
          icon=""
          variant="plain"
          density="compact"
          @click="getUserDocument(item)"
        ><v-icon size="small" :icon="getFileTypeIcon(item.DocumentFilenameSuffix)" />
          <v-tooltip
            activator="parent"
            location="top"
          >Download {{ item.DocumentFilenameSuffix }}
          </v-tooltip>
        </v-btn>
        <v-btn
          v-if="isAuthorizedAdmin"
          color="primary"
          icon=""
          variant="plain"
          density="compact"
          @click="showEditDialog(item)"
        ><v-icon size="small" icon="mdi-pencil" />
          <v-tooltip
            activator="parent"
            location="top"
          >Edit Metadata
          </v-tooltip>
        </v-btn>
        <v-btn
          v-if="isAuthorizedAdmin"
          color="error"
          icon=""
          variant="plain"
          density="compact"
          @click="showDeleteDialog(item)"
        ><v-icon size="small" icon="mdi-delete" />
          <v-tooltip
            activator="parent"
            location="top"
          >Delete Document
          </v-tooltip>
        </v-btn>
      </div>
    </template>
    <template v-slot:bottom> </template>
  </v-data-table>

  <v-dialog v-model="dialogEdit" max-width="750px">
      <v-form v-model="isEditValid">
        <v-card>
          <v-card-title>
            Edit Metadata
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <span class="font-weight-bold">{{ editDocumentFilename }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="editSelectedDocumentCategory"
                  label="Document Categories"
                  density="compact"
                  clearable
                  :rules="rulesIsRequired"
                  :items="editDocumentCategories"
                  :item-title="item => item.DocumentCategoryName"
                  :item-value="item => item.DocumentCategoryID"
                />
              </v-col>
              <v-col>
                <v-select
                  v-model="editSelectedDocumentType"
                  label="Document Types"
                  density="compact"
                  clearable
                  :rules="rulesIsRequired"
                  :items="editFilteredDocumentTypes"
                  :item-title="item => item.DocumentTypeName"
                  :item-value="item => item.DocumentTypeID"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  density="compact"
                  persistent-placeholder
                  variant="underlined"
                  label="Document Description"
                  v-model="editDocumentDescription"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" variant="text" :disabled="!isEditValid" @click="saveEditDialog(editUsersDocumentID, false)">Save</v-btn>
            <v-btn color="error" variant="text" @click="cancelEditDialog()">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="750px">
      <v-card>
        <v-card-title>
          Delete Document
        </v-card-title>
        <v-card-text>
          <v-row>
              <v-col>
                <span class="font-weight-bold">{{ editDocumentFilename }}</span>
              </v-col>
            </v-row>
          <v-row>
            <v-col>
              Are you sure you want to delete this document? This <span class="font-weight-bold">CANNOT</span> be undone!
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" variant="text" @click="saveEditDialog(editUsersDocumentID, true)">Yes</v-btn>
          <v-btn color="success" variant="text" @click="cancelDeleteDialog()">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
    <progressdialog v-model="downloadInProgress" message="Downloading Document, please hold..."></progressdialog>
</div></template>
<script setup>
import { ref, watch, onMounted } from 'vue'
import authService from '@/services/authService'
import utils from '@/services/utils'

const emit = defineEmits(["update:modelValue"])
const props = defineProps({
  userID: [String, Number],
  companyID: [String, Number],
  updateMessage: Function,
  readonly: Boolean,
})

const isAuthorizedAdmin = ref(false)

var isLoading = ref(true)

const userDocumentsHeaders = [
  { title: "Document Category", key: "DocumentCategoryID", value: "DocumentCategoryName"},
  { title: "Document Type", key: "DocumentTypeName", value: "DocumentTypeName"},
  { title: "Description", key: "DocumentDescription", value: "DocumentDescription"},
  { title: "Actions", key: "actions", width: "140px", sortable: false },
]

var search = ref(null)
var userDocuments = ref([])
var filteredUserDocuments = ref([])

var documentCategories = ref([])
var selectedDocumentCategory = ref(null)
    
var documentTypes = ref([])
var filteredDocumentTypes = ref([])
var selectedDocumentType = ref(null)

const rulesIsRequired = [
  (v) => !!v || 'Field is required'
]

const dialogUpload = ref(false)
const dialogEdit = ref(false)
const dialogDelete = ref(false)
const uploadInProgress = ref(false)
const downloadInProgress = ref(false)
const isUploadValid = ref(false)
const isEditValid = ref(false)

const newDocument = ref({
  DocumentDescription: null,
  DocumentObject: null,
  DocumentObjectBase64: null
})

const newDocumentCategories = ref([])
const newFilteredDocumentTypes = ref([])
const newDocumentTypes = ref([])
const newSelectedDocumentCategory =ref(null)
const newSelectedDocumentType =ref(null)

const editUsersDocumentID = ref(null)
const editDocumentCategories = ref([])
const editFilteredDocumentTypes = ref([])
const editDocumentFilename = ref(null)
const editDocumentTypes = ref([])
const editDocumentDescription = ref(null)
const editSelectedDocumentCategory = ref(null)
const editSelectedDocumentType = ref(null)

function updateResultMessage(message) {
  props.updateMessage(message)
}


watch(newSelectedDocumentCategory, (newValue, oldValue) => {
  filterNewTypes()
})

watch(editSelectedDocumentCategory, (newValue, oldValue) => {
  filterEditTypes()
})

watch(selectedDocumentCategory, (newValue, oldValue) => {
  filterTypes()
})

watch(selectedDocumentType, (newValue, oldValue) => {
  filterUserDocuments()
})

async function getDocumentCategories() {
  await utils.httpRequest(`/Static/DocumentCategories`, utils.REQUEST.GET, (data) => {
    documentCategories.value = data
  })
}

async function getDocumentTypes() {
  await utils.httpRequest(`/Static/DocumentTypes`, utils.REQUEST.GET, (data) => {
    documentTypes.value = data
  })
}

async function getUserDocument(item) {
  downloadInProgress.value =true

  await utils.httpRequest(`/Users/${item.UserID}/Documents/${item.UsersDocumentID}`, utils.REQUEST.GET, (response) => {
    const url = window.URL.createObjectURL(new Blob([response.blob]))
    const filename = response.filename
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    updateResultMessage("success|Download File|Downloaded User Document")
  })

  downloadInProgress.value = false
}

async function uploadNewDocument() {
  try {
    let payload = {}
    payload.DocumentCategoryID = newSelectedDocumentCategory.value
    payload.DocumentTypeID = newSelectedDocumentType.value
    payload.DocumentDescription = newDocument.value.DocumentDescription
    payload.DocumentFilename = newDocument.value.DocumentObject.name
    payload.IsActive = true
    payload.DocumentObject = newDocument.value.DocumentObjectBase64

    await utils.httpRequest(`/Users/${props.userID}/Documents`, utils.REQUEST.POST, (data) => {
        if (!data.Error) {
          updateResultMessage("success|Uploaded successfully|" + payload.DocumentFilename)
        } else {
          throw new Error(data.Error.Message)
        }
    },
    payload)
    newDocument.value.DocumentObject = {}
    newDocument.value.DocumentDescription = null
    await getDocuments()
  } catch (e) {
      updateResultMessage("error|Document Upload Error|" + e.message)
  }
}

function getFileTypeIcon(fileSuffix) {
  switch(fileSuffix.toLowerCase()) {
    case "pdf":
      return "mdi-file-pdf-box"
    case "msg":
      return "mdi-email"
    case "doc":
      return "mdi-file-word-box"
    case "docx":
      return "mdi-file-word-box"
    case "odt":
      return "mdi-file-word-box"
    case "xls":
      return "mdi-file-excel-box"
    case "xlsx":
      return "mdi-file-excel-box"
    case "png":
      return "mdi-file-png-box"
    case "jpg":
      return "mdi-file-jpg-box"
    case "txt":
      return "mdi-file-text-box"
    default:
      return "mdi-file-question"
  }
}

function filterNewTypes() {
  newSelectedDocumentType.value = ""
  newFilteredDocumentTypes.value = newDocumentTypes.value.filter(function(item) {
    return item.DocumentCategoryID == newSelectedDocumentCategory.value
    }).sort(function(a, b) {
      return a.DisplayOrderID - b.DisplayOrderID
    })
}

function filterEditTypes() {
  editFilteredDocumentTypes.value = editDocumentTypes.value.filter(function(item) {
    return item.DocumentCategoryID == editSelectedDocumentCategory.value
    }).sort(function(a, b) {
      return a.DisplayOrderID - b.DisplayOrderID
    })

  if (!editFilteredDocumentTypes.value.some(item => item.DocumentTypeID === editSelectedDocumentType.value)) {
    editSelectedDocumentType.value = ""
  }
}

function filterTypes() {
  selectedDocumentType.value = ""
  filteredDocumentTypes.value = documentTypes.value.filter(function(item) {
    return item.DocumentCategoryID == selectedDocumentCategory.value
    }).sort(function(a, b) {
      return a.DisplayOrderID - b.DisplayOrderID
    })
  filterUserDocuments()
}

function filterUserDocuments() {
  filteredUserDocuments.value = userDocuments.value.filter(function(item) {
    let sdc = selectedDocumentCategory.value ? item.DocumentCategoryID == selectedDocumentCategory.value : true
    let sdsc = selectedDocumentType.value ? item.DocumentTypeID == selectedDocumentType.value : true
    return sdc && sdsc
    }).sort(function(a, b) {
      if (a.DocumentCategoryID == b.DocumentCategoryID) {
        return (a.DocumentTypeOrder < b.DocumentTypeOrder) ? -1 : (a.DocumentTypeOrder > b.DocumentTypeOrder) ? 1 : 0
      } else {
        return (a.DocumentCategoryOrder < b.DocumentCategoryOrder) ? -1 : 1
      }
    })
}

async function getLookups() {
  await getDocumentCategories()
  await getDocumentTypes()
}

async function getDocuments() {
  if (props.userID) {
    await utils.httpRequest(`/Users/${props.userID}/Documents`, utils.REQUEST.GET, (data) => {
      userDocuments.value = data
    })
  }
  filterUserDocuments()
}

function showDeleteDialog(item) {
  dialogDelete.value = true

  editUsersDocumentID.value = item.UsersDocumentID
  editSelectedDocumentCategory.value = item.DocumentCategoryID
  editSelectedDocumentType.value = item.DocumentTypeID
  editDocumentDescription.value = item.DocumentDescription
  editDocumentFilename.value = item.DocumentFilename
}

function showEditDialog(item) {
  dialogEdit.value = true
  editDocumentCategories.value = documentCategories.value
  editDocumentTypes.value = documentTypes.value

  editUsersDocumentID.value = item.UsersDocumentID
  editSelectedDocumentCategory.value = item.DocumentCategoryID
  editSelectedDocumentType.value = item.DocumentTypeID
  editDocumentDescription.value = item.DocumentDescription
  editDocumentFilename.value = item.DocumentFilename
}

function showUploadDialog() {
  dialogUpload.value = true
  newDocumentCategories.value = documentCategories.value
  newDocumentTypes.value = documentTypes.value
  newSelectedDocumentCategory.value = selectedDocumentCategory.value
  newSelectedDocumentType.value = selectedDocumentType.value
}

async function saveUploadDialog() {
  uploadInProgress.value = true
  await utils.convertFileToBase64(newDocument.value.DocumentObject).then(base64 => {
    newDocument.value.DocumentObjectBase64 = base64
  })
  
  await uploadNewDocument()
  uploadInProgress.value = false
  dialogUpload.value = false
  selectedDocumentCategory.value = ""
}

async function saveEditDialog(userDocumentID, mustDelete) {
  try {
    let payload = {}
    payload.DocumentCategoryID = editSelectedDocumentCategory.value
    payload.DocumentTypeID = editSelectedDocumentType.value
    payload.DocumentDescription = editDocumentDescription.value
    payload.DocumentFilename = editDocumentFilename.value
    payload.IsActive = !mustDelete

    await utils.httpRequest(`/Users/${props.userID}/Documents/${userDocumentID}`, utils.REQUEST.POST, (data) => {
        if (!data.Error) {
          updateResultMessage("success|Saved successfully|" + payload.DocumentFilename)
        } else {
          throw new Error(data.Error.Message)
        }
    },
    payload)

    await getDocuments()
  } catch (e) {
      updateResultMessage("error|Document Upload Error|" + e.message)
  }

  dialogEdit.value = false
  dialogDelete.value = false
  selectedDocumentCategory.value = ""
}

function cancelUploadDialog() {
  dialogUpload.value = false
}

function cancelEditDialog() {
  dialogEdit.value = false
}

function cancelDeleteDialog() {
  dialogDelete.value = false
}

onMounted(async () => {
  isAuthorizedAdmin.value = (await authService.isHumanCapitalAdmin()) || authService.isAdminForCompany(props.companyID)
  await getLookups()
  await getDocuments()
  isLoading.value = false
})
</script>
