<template><div>
  <v-card
    border="secondary lg opacity-100"
    class="mx-auto d-flex flex-column"
    :height=props.height
    rounded="xl"
    variant="text"
  >
    <v-card-title class="bg-secondary"><v-icon icon="mdi-cake-variant"></v-icon> Birthday List</v-card-title>
    <v-card-text
      class="mt-3 mb-3"
      style="overflow-y: auto"
    >
      <v-data-table
        density="compact"
        :headers="birthdayListHeaders"
        :items="birthdayList"
        items-per-page="-1"
        hide-default-footer="true">
        <template v-slot:item.Name="{ item }">
          <v-btn
            variant="text"
            size="small"
            color="secondary"
            icon=""
            @click="sendMail(item.EmailAddress)"
          ><v-icon icon="mdi-email" />
            <v-tooltip
              activator="parent"
              location="top"
            >Send mail to {{ item.EmailAddress }}
            </v-tooltip>
          </v-btn>
          {{ item.Name }} ({{ item.CompanyCode }})
        </template>
        <template v-slot:item.DateOfBirth="{ item }">
          {{ formatDatePeriod(item.DateOfBirth) }}
        </template>
        <template v-slot:bottom> </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</div></template>
<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import authService from '@/services/authService'
import utils from '@/services/utils'
import { useDate } from 'vuetify'
import { useRouter } from 'vue-router'

const props = defineProps({
  updateMessage: Function,
  height: String,
})

const dateAdapter = useDate()
const router = useRouter()

const today = dateAdapter.toISO(new Date())

const userID = localStorage.getItem("user_id")
const birthdayList = ref([])
const birthdayListHeaders = [
  { title: "Name", key: "Name", value: "Name"},
  { title: "Birthday", key: "DateOfBirth", value: "DateOfBirth", width: "140px", sortable: false},
]

function formatDatePeriod(dt) {
  return utils.formatDatePeriod(dt, true, true, false)
}

function updateResultMessage(message) {
  props.updateMessage(message)
}

function sendMail(emailAddress) {
  window.open(`mailto:${emailAddress}?subject=Happy Birthday!`)
  updateResultMessage("success|Sending Mail|" + emailAddress)
}

async function getBirthdayList() {
  await utils.httpRequest(`/Users/${userID}/CoworkerBirthdays`, utils.REQUEST.GET, (data) => {
    birthdayList.value = data
  })
}

onMounted(async () => {
  if (userID) {
    await getBirthdayList()
  }
})
</script>
